import React, { Component } from 'react';

import * as formik from 'formik';
import moment from 'moment';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Col'
import DateTimePicker from 'react-datetime-picker';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import { graphql } from '@apollo/react-hoc';
import compose from "lodash.flowright";
import * as mutations from "./graphql/mutations.js";
import * as queries from "./graphql/queries.js";
import gql from 'graphql-tag';
// import { v4 as uuidv4 } from 'uuid';

import * as config from './client-config.js';
import update from 'immutability-helper';
import FieldSpy from './FieldSpy.js';
import FormikPlacesAutocomplete from './PlacesInput.js';
import { Field } from "formik";
import MySwal from './MySwal';
import LocationPOI from './LocationPOI';

import BootstrapTable from 'react-bootstrap-table-next';
import { FaTrash } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { FaRedo } from 'react-icons/fa';

const uuidv4 = require('uuid/v4');
const { Formik } = formik;
const { ClientConfig } = config;

class ManualOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: "",
            location: "",
        }
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
    }
    handleClientChange(event) {
        let client = event.target.value
        this.setState({ client: client });

        if (client !== "") {
            var locationOptions = [];
            locationOptions.push(<option key="blank" value="">Select location</option>)
            Object.keys(ClientConfig[client]).forEach(location => locationOptions.push(<option key={location} value={location}>{location[0].toUpperCase() + location.substring(1)}</option>))

            this.setState({ locationOptions: locationOptions, location: locationOptions[0].props.value });
        }
        else {
            this.setState({ locationOptions: [], location: "" });
        }
    }
    handleLocationChange(event) {
        let location = event.target.value
        if ((this.state.client in ClientConfig) && (location in ClientConfig[this.state.client])) {
            this.setState({ location: location });
        }


        //this.setState({ location: location });
    }
    componentDidMount() {
        var clientOptions = [];
        clientOptions.push(<option key="blank" value="">Select client</option>)
        Object.keys(ClientConfig).forEach(client => clientOptions.push(<option key={client} value={client}>{client[0].toUpperCase() + client.substring(1)}</option>))

        var locationOptions = [];

        this.setState({
            clientOptions: clientOptions,
            locationOptions: locationOptions
        });

        if ("client" in this.props) {
            this.setState({ client: this.props.client }, this.handleClientChange({ "target": { "value": this.props.client } }))


            if ("location" in this.props) {
                this.setState({ client: this.props.client, location: this.props.location }, () => {
                    this.handleClientChange({ "target": { "value": this.props.client } })
                    this.handleLocationChange({ "target": { "value": this.props.location } })
                })
            }
        }

    }

    render() {
        return (
            <React.Fragment key="Manual Order">
                <div className="App-header">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Row}>
                                <Form.Control as="select" onChange={this.handleClientChange} value={this.state.client}>
                                    {this.state.clientOptions}
                                </Form.Control>
                            </Form.Group>
                            {this.state.client &&
                                <Form.Group as={Row}>
                                    <Form.Control as="select" onChange={this.handleLocationChange} value={this.state.location}>
                                        {this.state.locationOptions}
                                    </Form.Control>
                                </Form.Group>}
                        </Form.Row>
                    </Form>
                </div>
                {this.state.client && this.state.location &&
                    <ManualOrderPageWithData client={this.state.client} location={this.state.location} user={this.props.user} />
                }
            </React.Fragment>)
    }
}

class ManualOrderPage extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePreorder = this.handlePreorder.bind(this);
        this.removePreorder = this.removePreorder.bind(this);
        this.editPreorder = this.editPreorder.bind(this);
        this.orderForm = React.createRef();
        if ((props.client in ClientConfig) && (props.location in ClientConfig[props.client])) {
            let clientConfig = ClientConfig[props.client][props.location]
            clientConfig.fields.forEach(field => {
                if ("onChangeCallbackName" in field) {
                    field.onChangeCallback = this[field.onChangeCallbackName].bind(this)
                }
            })

            this.state = {
                submitInProgress: false,
                schema: clientConfig.schema,
                fields: clientConfig.fields,
                formInitValues: clientConfig.formInitValues,
                showForm: true
            }

            this.setCleenerList(clientConfig.formInitValues.serviceOrderType);
            this.clientConfig = clientConfig
        }
    }





    setCleenerList(value) {
        Auth.currentCredentials().then(credentials => {
            const cloudformation = new CloudFormation({
                credentials: Auth.essentialCredentials(credentials),
                region: "eu-west-1"
            });
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: "eu-west-1"
            });

            var params = {
                StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
            };
            cloudformation.describeStacks(params).promise().then(data => {
                var awsStackOutputs = data.Stacks[0].Outputs
                return awsStackOutputs.filter(obj => {
                    return obj.OutputKey === "getCleenerListArn";
                })[0].OutputValue
            }, error => {
                console.log(error)
            }).then((functionName) => {
                var payload = {
                    "client": this.props.client,
                    "location": this.props.location,
                }
                var pullParams = {
                    FunctionName: functionName,
                    InvocationType: 'RequestResponse',
                    LogType: 'None',
                    Payload: JSON.stringify(payload)
                };
                return lambda.invoke(pullParams).promise();
            }).then(
                result => {
                    let payload = JSON.parse(result.Payload);
                    if (payload.statusCode === 200) {
                        try {
                            let body = JSON.parse(payload.body);
                            let cleenerArray = []
                            let accessArray = this.state.fields.find(x => x.name === "serviceOrderType").optionsValues[value].access;

                            for (var i = 0; i < accessArray.length; i++) {
                                cleenerArray = cleenerArray.concat(body[accessArray[i]])
                            }
                            let index = this.state.fields.findIndex(x => x.name === "cleener");
                            if (index !== -1) {

                                const arrayToObject = (array) =>
                                    array.reduce((obj, item) => {
                                        obj[item["Trello Name"]] = { label: item['First Name'] + " " + item['Last Name'] + ' (Cleener-' + item["CleenerID"].toString() + ')' }
                                        return obj
                                    }, {})

                                const newData = update(this.state.fields, {
                                    [index]: { optionsValues: { $set: arrayToObject(cleenerArray) } }
                                });

                                this.setState({ fields: newData })
                            }
                        }
                        catch{

                            let index = this.state.fields.findIndex(x => x.name === "cleener");
                            if (index !== -1) {
                                const newData = update(this.state.fields, {
                                    [index]: { optionsValues: { $set: [] } }
                                });

                                this.setState({ fields: newData })
                            }

                        }
                    }
                    else {
                        MySwal.displaySystemError(payload.body)
                    }
                },
                err => {
                    MySwal.displaySystemError(err)

                })

        })
    }

    handleSubmit(row) {
        let removePreorder = this.removePreorder
        let values = JSON.parse(row.requestPayload)
        values.completionFrom = moment(values.completionFrom).toDate()
        values.completionUntil = moment(values.completionUntil).toDate()


        this.setState({ submitInProgress: row.id }, () =>
            Auth.currentCredentials().then(credentials => {
                const cloudformation = new CloudFormation({
                    credentials: Auth.essentialCredentials(credentials),
                    region: "eu-west-1"
                });
                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: "eu-west-1"
                });

                var params = {
                    StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
                };
                cloudformation.describeStacks(params).promise().then(data => {
                    var awsStackOutputs = data.Stacks[0].Outputs
                    return awsStackOutputs.filter(obj => {
                        return obj.OutputKey === "createCardArn";
                    })[0].OutputValue
                }, error => {
                    console.log(error)
                }).then((functionName) => {


                    let positionGPSQueryString =
                        ("positionGPS" in values && values.positionGPS.length > 0 ?
                            encodeURI(values.positionGPS)
                            :
                            "coordinates" in values.position && "lat" in values.position.coordinates && "lng" in values.position.coordinates ?
                                encodeURI(values.position.coordinates.lat + "," + values.position.coordinates.lng)
                                :
                                encodeURI(values.position.address))

                    let positionGPSField =
                        ("positionGPS" in values && values.positionGPS.length > 0 ?
                            values.positionGPS
                            :
                            "coordinates" in values.position && "lat" in values.position.coordinates && "lng" in values.position.coordinates ?
                                values.position.coordinates.lat + "," + values.position.coordinates.lng
                                :
                                "")


                    var payload = {
                        "OrderID": values.orderId,
                        "Fahrzeuginformation": values.carType,
                        "Fahrzeug": values.plateNumber,
                        "Ladestand": "",
                        "Team": (("cleener" in values) ? values.cleener : ""),
                        "Bezirk": this.props.location,
                        "Position": values.position.address,
                        "PositionLink": "https://www.google.com/maps/search/?api=1&query=" + positionGPSQueryString,
                        "PositionGPS": positionGPSField,
                        "Schaden": "",
                        "Kommentare": "",
                        "BlockTyp": values.serviceOrderType,
                        "BlockKommentare": (("comment" in values) ? values.comment : ""),
                        "Fahrzeugstatus": "",
                        "ErfasstVon": "CleenUp Operator (" + this.props.user.attributes.email + ")",
                        "Auftragsdatum": moment().format("YYYY-MM-DD HH:mm:ss"),
                        "CarOpenLink": (("carOpenLink" in values) ? values.carOpenLink : ""),
                        "ServiceDateFrom": (("completionFrom" in values) ? moment(values.completionFrom).format("YYYY-MM-DD HH:mm:ss") : ""),
                        "ServiceDateTill": (("completionUntil" in values) ? moment(values.completionUntil).format("YYYY-MM-DD HH:mm:ss") : ""),
                        "Client": this.props.client,
                        "Location": this.props.location
                    }
                    var pullParams = {
                        FunctionName: functionName,
                        InvocationType: 'RequestResponse',
                        LogType: 'None',
                        Payload: JSON.stringify(payload)
                    };
                    return lambda.invoke(pullParams).promise();
                }).then(
                    result => {
                        let payload = JSON.parse(result.Payload);
                        if (payload.statusCode === "200") {

                            MySwal.fire({
                                type: 'success',
                                title: "Success!",
                                text: "Order created for " + this.props.client + " " + this.props.location + " on " + JSON.parse(payload.body)["board"]["name"] + ".",
                                showConfirmButton: false,
                                timer: 3000,
                                onClose: function () {
                                    //resetForm()
                                    removePreorder(row)
                                }
                            })
                        }
                        else {
                            MySwal.displaySystemError(payload.body)
                        }
                    },
                    err => {
                        MySwal.displaySystemError(err)

                    }).finally(() => { this.setState({ submitInProgress: false }) })

            }));
    }






    renderFields(fields, values, handleChange, setFieldValue, setFieldError, touched, errors, setFieldTouched) {
        return fields.map(field => {
            if (field.type === "select") {
                var options = [];
                options.push(<option key="blank" value="">Please select</option>)
                Object.keys(field.optionsValues).forEach(optionValue => options.push(<option key={optionValue} value={optionValue}>{field.optionsValues[optionValue].label}</option>))
                return (
                    <Form.Group as={Col} md={6} lg={6} controlId={field.name} key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            as="select"
                            name={field.name}
                            value={values[field.name]}
                            onChange={handleChange}
                            disabled={this.state.submitInProgress}
                            isValid={touched[field.name] && !errors[field.name]}
                            isInvalid={touched[field.name] && !!errors[field.name]}
                            onBlur={() => setFieldTouched(field.name, true)}
                        >
                            {options}
                        </Form.Control>
                        {field.onChangeCallback &&
                            <FieldSpy name={field.name} onChange={field.onChangeCallback} />
                        }
                        <Form.Control.Feedback type="invalid">
                            {!!errors[field.name] && "Required"}
                        </Form.Control.Feedback>
                    </Form.Group>)
            }
            else if (field.type === "datetime") {
                return (
                    <Form.Group as={Col} md={6} lg={6} controlId={field.name} key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control as={DateTimePicker}
                            name={field.name}
                            format="y-MM-dd HH:mm"
                            value={values[field.name]}
                            disableClock={true}
                            //maxDate={values['completionUntil']}
                            calendarIcon={null}
                            clearIcon={null}
                            //minDate={new Date()}
                            onChange={e => setFieldValue(field.name, e)}
                            isInvalid={touched[field.name] && !!errors[field.name]}
                            disabled={this.state.submitInProgress}
                            onBlur={() => setFieldTouched(field.name, true)}
                            isValid={touched[field.name] && !errors[field.name]}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors[field.name]}
                        </Form.Control.Feedback>
                    </Form.Group>)
            }
            else if (field.type === "locationinput") {
                return (<Form.Group md={6} lg={6} as={Col} controlId={field.name} key={field.name}>
                    <Form.Label>{field.label}</Form.Label>
                    <Field name={field.name} disabled={this.state.submitInProgress} touched={touched} errors={errors} setFieldError={setFieldError} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} value={values[field.name]} component={FormikPlacesAutocomplete} />
                </Form.Group>
                )
            }
            else if (field.type === "POImap") {
                return (
                    <Col md={12} key={field.name}>
                        <LocationPOI controlId={field.name} key={field.name} label={field.label} client={this.props.client} location={this.props.location} setFieldValue={setFieldValue} />
                    </Col>
                )
            }
            else {
                return (<Form.Group as={Col} md={6} lg={6} controlId={field.name} key={field.name}>
                    <Form.Label>{field.label}</Form.Label>
                    <Form.Control
                        type="text"
                        name={field.name}
                        placeholder={field.label}
                        value={values[field.name]}
                        disabled={this.state.submitInProgress}
                        onChange={handleChange}
                        isValid={touched[field.name] && !errors[field.name]}
                        isInvalid={touched[field.name] && !!errors[field.name]}
                        onBlur={() => setFieldTouched(field.name, true)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[field.name]}
                    </Form.Control.Feedback>
                </Form.Group>)
            }
        })
    }

    actions = (cell, row, rowIndex, formatExtraData) => {
        return formatExtraData(row)
    };

    removePreorder(cell) {
        // alert(JSON.stringify(cell))
        this.props.deletePreorder(cell)
    }

    editPreorder(cell) {
        // alert(JSON.stringify(cell))
        let formInitValues = JSON.parse(cell.requestPayload)
        formInitValues.completionFrom = moment(formInitValues.completionFrom).toDate()
        formInitValues.completionUntil = moment(formInitValues.completionUntil).toDate()
        this.setState({ formInitValues: formInitValues })
        this.orderForm.handleReset()
    }

    handlePreorder(values, resetForm) {
        let existingElement = this.props.event.items.find(o=>o.orderId === values["orderId"])
        if((this.props.event) && existingElement)
        {
            this.props.updatePreorder({
                timestamp: moment().unix() * 1e3,
                id: existingElement.id,
                orderId: values.orderId,
                serviceDateFrom: (("completionFrom" in values) ? moment(values.completionFrom).format("YYYY-MM-DD HH:mm") : ""),
                serviceDateTill: (("completionUntil" in values) ? moment(values.completionUntil).format("YYYY-MM-DD HH:mm") : ""),
                plateNumber: values.plateNumber,
                carType: values.carType,
                orderType: values.serviceOrderType,
                client: this.props.client,
                location: this.props.location,
                createdBy: this.props.user.attributes.email,
                requestPayload: JSON.stringify(values)
            })

        }
        else {                               
            this.props.createPreorder({
                timestamp: moment().unix() * 1e3,
                id: uuidv4(),
                orderId: values.orderId,
                serviceDateFrom: (("completionFrom" in values) ? moment(values.completionFrom).format("YYYY-MM-DD HH:mm") : ""),
                serviceDateTill: (("completionUntil" in values) ? moment(values.completionUntil).format("YYYY-MM-DD HH:mm") : ""),
                plateNumber: values.plateNumber,
                carType: values.carType,
                orderType: values.serviceOrderType,
                client: this.props.client,
                location: this.props.location,
                createdBy: this.props.user.attributes.email,
                requestPayload: JSON.stringify(values)
            })
        }
        this.setState({formInitValues: this.clientConfig.formInitValues},()=>resetForm())
    }


    render() {
        let columns = [
            {
                dataField: 'orderId',
                text: 'Order ID',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            }, {
                dataField: 'serviceDateTill',
                text: 'Completion Until',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'plateNumber',
                text: 'Plate Number',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'carType',
                text: 'Car Type',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'orderType',
                text: 'Order Type',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'Actions',
                text: 'Actions',
                formatExtraData: (row) => {

                    return (
                        <React.Fragment>
                            <Button variant="outline-primary"
                                disabled={!!this.state.submitInProgress}
                                onClick={() => {
                                    this.handleSubmit(row);
                                }}
                            >
                                {
                                this.state.submitInProgress === row.id ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    :
                                    <FaPaperPlane />
                                }
                            </Button>&nbsp;
                            <Button variant="outline-secondary"
                                disabled={!!this.state.submitInProgress}
                                onClick={() => {
                                    this.editPreorder(row);
                                }}
                            >
                                <FaEye />
                            </Button>&nbsp;

                            <Button variant="outline-danger"
                                disabled={!!this.state.submitInProgress}
                                onClick={() => {
                                    this.removePreorder(row);
                                }}
                            >
                                <FaTrash />
                            </Button>

                        </React.Fragment>)

                },
                formatter: this.actions
            }];


        return (

            <div className="container">

                <Formik
                    ref={orderForm => {
                        this.orderForm = orderForm;
                    }}
                    key={this.props.client + this.props.location}
                    validationSchema={this.state.schema}
                    //onSubmit={(values, { resetForm }) => { this.handleSubmit(values, resetForm) }}
                    onSubmit={(values, { resetForm }) => { this.handlePreorder(values, resetForm) }}

                    initialValues={this.state.formInitValues}
                    enableReinitialize={true}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched,
                        handleReset,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                {this.state.showForm &&
                                <React.Fragment>
                                <Form.Row>
                                    {this.renderFields(this.state.fields, values, handleChange, setFieldValue, setFieldError, touched, errors, setFieldTouched)}
                                </Form.Row>
                                
                                <Form.Row>
                                    <div className="col text-center">
                                        {/* <Button type="submit" variant="primary" disabled={this.state.submitInProgress}>
                                                    {this.state.submitInProgress &&
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />}
                                                    <FaPaperPlane />&nbsp;Submit</Button>&nbsp; */}
                                        
                                        {((this.props.event) && (this.props.event.items.find(o=>o.orderId === values["orderId"])))?
                                        <React.Fragment>
                                        <Button type="submit" variant="primary" disabled={this.state.submitInProgress}>
                                        <FaRedo/>&nbsp;Update
                                        </Button>&nbsp;
                                        <Button onClick={()=>this.setState({formInitValues: this.clientConfig.formInitValues},()=>handleReset())} variant="secondary" disabled={this.state.submitInProgress}>
                                        <FaTimes/>&nbsp;Clear
                                        </Button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                        <Button type="submit" variant="primary" disabled={this.state.submitInProgress}>
                                        <FaPlus />&nbsp;Add to list
                                        </Button>&nbsp;
                                        <Button onClick={()=>this.setState({formInitValues: this.clientConfig.formInitValues},()=>handleReset())} variant="secondary" disabled={this.state.submitInProgress}>
                                        <FaTimes/>&nbsp;Clear
                                        </Button>
                                        </React.Fragment>
}
                                        <hr />
                                    </div>

                                </Form.Row>
                                </React.Fragment>}
                                <Form.Row>
                                    <BootstrapTable striped hover condensed keyField='id' data={this.props.event ? this.props.event["items"] : []} columns={columns} noDataIndication={<p>No orders in list</p>} disabled={this.state.submitInProgress} />
                                </Form.Row>
                            </Form>
                        )}
                </Formik>

            </div>

        );
    }
}



const ManualOrderPageWithData = compose(
    graphql(gql(queries.preorderByClientByTimestamp),
        {
            options: props => ({
                variables: {
                    client: props.client,
                    filter: {
                        location: {
                            eq: props.location
                        },
                    },
                    sortDirection: "DESC",
                },
                fetchPolicy: "cache-and-network",
                // pollInterval:0,
                // errorPolicy:"ignore"
            }),
            props: ({ data: { preorderByClientByTimestamp: event, loading, error } }) => ({
                event,
                loadingPreorders: loading,
                errorPreorders: error
            }),
        }),
    graphql(gql(mutations.createPreorder),
        {
            props: (props) => ({
                createPreorder: (event) => {
                    console.log("createPreorder called")

                    return props.mutate({
                        variables: { "input": event },
                        update: (proxy, { data: { createPreorder } }) => {

                            const query = gql(queries.preorderByClientByTimestamp);
                            const variables = {
                                client: props.ownProps.client,
                                filter: {
                                    location: {
                                        eq: props.ownProps.location
                                    },
                                },
                                sortDirection: "DESC",
                            }
                            const data = proxy.readQuery({ query: query, variables: variables });
                            data.preorderByClientByTimestamp.items = [createPreorder, ...data.preorderByClientByTimestamp.items.filter(e => e.id !== createPreorder.id)];
                            proxy.writeQuery({ query, variables, data });
                        },
                        optimisticResponse: () => ({
                            createPreorder: {
                                ...event, __typename: 'Preorder'
                            }
                        }),

                    }).catch((e) => console.log(e))
                }
            })
        }),
    graphql(gql(mutations.deletePreorder),
        {
            props: (props) => ({
                deletePreorder: (event) => {
                    console.log("deletePreorder called")
                    return props.mutate({
                        variables: { "input": { "id": event.id } },
                        update: (proxy, { data: { deletePreorder } }) => {
                            // Update listChargePeriods
                            const query = gql(queries.preorderByClientByTimestamp);
                            const variables = {
                                client: props.ownProps.client,
                                filter: {
                                    location: {
                                        eq: props.ownProps.location
                                    },
                                },
                                sortDirection: "DESC",
                            }
                            //console.log(JSON.stringify(deletePreorder))
                            const data = proxy.readQuery({ query: query, variables: variables });
                            data.preorderByClientByTimestamp.items = data.preorderByClientByTimestamp.items.filter(event => event.id !== deletePreorder.id);
                            proxy.writeQuery({ query: query, data: data, variables: variables });
                        },
                        optimisticResponse: () => ({
                            deletePreorder: {
                                ...event,
                                //__typename: 'Preorder'
                            }
                        }),

                    }).catch((e) => console.log(e))
                }
            })
        }),
    graphql(gql(mutations.updatePreorder),
        {
            props: (props) => ({
                updatePreorder: (event) => {
                    console.log("updatePreorder called")

                    return props.mutate({
                        variables: {
                            input: event
                        },
                        update: (proxy, { data: { updatePreorder } }) => {
                            const query = gql(queries.preorderByClientByTimestamp);
                            const variables = {
                                client: props.ownProps.client,
                                filter: {
                                    location: {
                                        eq: props.ownProps.location
                                    },
                                },
                                sortDirection: "DESC",
                            }
                            //console.log(JSON.stringify(updatePreorder))
                            const data = proxy.readQuery({ query: query, variables: variables });
                            data.preorderByClientByTimestamp.items = [updatePreorder, ...data.preorderByClientByTimestamp.items.filter(event => event.id !== updatePreorder.id)].sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);
                            proxy.writeQuery({ query: query, data: data, variables: variables });

                        },
                        optimisticResponse: () => ({
                            updatePreorder: {
                                ...event,
                                __typename: 'Preorder'
                            }
                        }),

                    }).catch((e) => console.log(e))
                }
            })
        })
)(ManualOrderPage);

export default ManualOrder