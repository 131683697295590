import Form from 'react-bootstrap/Form';
import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { getIn } from 'formik';
class FormikPlacesAutoComplete extends Component {

  constructor(props) {
    super(props)
    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleError = error => {
    this.props.setFieldError(this.props.field.name, error);
  };

  handleChange = address => {
    this.setState(() => {
      this.props.setFieldTouched(this.props.field.name);
      this.props.setFieldValue(this.props.field.name, { address: address, coordinates:null });
      return { address };
    });
  };

//   componentDidUpdate(prevProps, prevState) {
//     if(prevProps.value) {
//     if(prevProps.value !== this.props.value){
//     if (this.props.value==="") {
//       // prop value reset to default, therefore reset was hit on the form
//       this.setState({ address: "" }, this.props.setFieldValue(this.props.field.name, { value: "", coordinates:null }));
//     }
//   }}
// }

  
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState(() => {
          this.props.setFieldValue(this.props.field.name, {
            address: address,
            coordinates: latLng
          });
          return { address };
        });
      })
      .catch(error => this.props.setFieldError(this.props.field.name, error));
  };

  render() {
    const {
      field:{name},
      touched, 
      errors,
      ...props
    } = this.props;
    
    const searchOptions = {
      componentRestrictions: {country: 'de'}
    }
    return (
      <PlacesAutocomplete
        name={name}
        id={name}
        {...props}
        value={this.props.value.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        searchOptions={searchOptions}
        debounce={500}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Form.Control
            type="text"
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input form-control"
              })}

              isValid={
                getIn(touched, name) &&
                !getIn(errors, name)
              }
              isInvalid={
                getIn(touched, name) && !!getIn(errors, name)
              }
            disabled={this.props.disabled}
            onBlur={() => this.props.setFieldTouched(name)}
            />
            
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default FormikPlacesAutoComplete;
