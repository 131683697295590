import React from 'react';
import moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';

import { Connect } from "aws-amplify-react";
import  { graphqlOperation } from "aws-amplify";
import * as queries from './graphql/queries';
import * as subscriptions from './graphql/subscriptions';
//import * as subscriptions from './graphql/subscriptions';
import { compose, withProps } from "recompose"
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"


const API_KEY = "AIzaSyATJvT9Dnm3t4Ff-3i2msxTGKTmGXcjAl8"

const mapEnvironment = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `80vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  //withScriptjs,
  withGoogleMap
);


const LiveMapLayout = props => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -34.397, lng: 150.644 }}
    ref={props.onMapMounted}
    options={{ streetViewControl: false, fullscreenControl: false }}
  >
    {props.markers.map(marker =>
      <Marker key={marker.cleenerId+marker.deviceName+marker.timestamp} position={marker} icon={"./cleener_icon.png"}>
        <InfoWindow>
          <div>
            {marker.cleenerName !== "" ? marker.cleenerName : "Cleener-" + marker.cleenerId}<br />{marker.deviceName}<br />{moment(marker.timestamp).fromNow()}</div>
        </InfoWindow>
      </Marker>)}
  </GoogleMap>
);
const LiveMapComponent = mapEnvironment(LiveMapLayout);




export default class LocationLive extends React.Component {
  constructor(props) {
    super(props)
    this.handleMapMounted = this.handleMapMounted.bind(this);

  }

  handleMapMounted = (map) => {
    const { markers } = this

    this._map = map
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()

      markers.forEach(marker => {
        //bounds.extend(marker)
        bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng + 0.5 / 111 })
        bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng + 0.5 / 111 })
        bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng - 0.5 / 111 })
        bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng - 0.5 / 111 })
      })

      this._map.fitBounds(bounds)
      //map.setZoom(map.getZoom() - 1);
    }
  }

  render() {
    // const ListView = ({ locations }) => (
    //   <div>
    //     <h3>All Locations</h3>
    //     <ul>
    //       {locations.map(location => <li key={uuidv4()}>{location.installationId},{location.deviceName},{location.timestamp},{location.accuracy},{location.latitude},{location.longitude}</li>)}
    //     </ul>
    //   </div>
    // );


    var timeFrom = moment().subtract(60, 'minutes');
    let variables =
    {
      "city": this.props.location,
      "limit": 1500,
      "sortDirection": "DESC",
      "timestamp": {
        "gt": timeFrom.unix() * 1e3
      }
    }


    return (
      <Connect
        query={graphqlOperation(queries.locationByCityByTimestamp, variables)}
        subscription={graphqlOperation(subscriptions.onCreateLocation, {"city": this.props.location})}
        onSubscriptionMsg={(prev, { onCreateLocation }) => {
          //console.log ( onCreateLocation );
          //alert("prev:"+JSON.stringify(prev)+ ", onCreateLocation:"+JSON.stringify(onCreateLocation))
          if("locationByCityByTimestamp" in prev && "items" in prev.locationByCityByTimestamp) {
          prev.locationByCityByTimestamp.items.unshift(onCreateLocation)
          return {
            locationByCityByTimestamp: {
              items:prev.locationByCityByTimestamp.items.slice(0,100)}
            };
          }
          else {
            return {
              locationByCityByTimestamp:{items:[onCreateLocation]}
            }
          }
        }}

      >
        {({ data, loading, errors }) => {
          //console.log("query ran")
          //console.log("data:"+JSON.stringify(data))
          //console.log("loading:"+JSON.stringify(loading))
          //console.log("errors:"+JSON.stringify(errors))
          let listLocations = data.locationByCityByTimestamp
          if (Array.isArray(errors) && (errors.length > 0)) {
            return (<h3>Error: {JSON.stringify(errors)}</h3>);
          }
          if (loading || !listLocations || !listLocations.items) return (<center><Spinner animation="border" /></center>);
          if (listLocations.items.length === 0) return (<h3><center>No data</center></h3>);
          if (listLocations.items.length > 0) {

            var uniqueInstallations = [...new Set(listLocations.items.map(location => location.installationId))]

            this.markers = []
            uniqueInstallations.forEach((installationId, index) => {
              var marker = listLocations.items.filter(location => location.installationId === installationId).reduce((prev, current) => {
                return (prev.timestamp > current.timestamp) ? prev : current
              })

              let cleenerName = ""
              //alert(JSON.stringify(this.props.cleenerInfoDictionary))
              try {
                cleenerName = this.props.cleenerInfoDictionary[marker.cleenerId.toString()].label
              }
              catch { }

              this.markers.push({
                timestamp: marker.timestamp,
                deviceName: marker.deviceName,
                cleenerName: cleenerName,
                cleenerId: marker.cleenerId,
                lat: marker.latitude,
                lng: marker.longitude
              })
            }
            )
            //this.setState({markers:this.markers})

            return (<React.Fragment>
              {<LiveMapComponent
                markers={this.markers}
                onMapMounted={this.handleMapMounted}
              />}
              {/* {<ListView locations={listLocations.items} />} */}
            </React.Fragment>);
          }
        }
        }
      </Connect>
    )
  }
}
