import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import MySwal from './MySwal';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { FaFileDownload } from 'react-icons/fa';

const { SearchBar } = Search;
// const MyExportCSV = (props) => {
//   const handleClick = () => {
//     props.onExport();
//   };
//   return (
//     <Button variant="secondary" onClick={handleClick}><FaFileDownload/>&nbsp;Download</Button>
//   );
// };
class ViewPointAdjusmentsModal extends Component {
    constructor(props) {
      super(props);
      this.getPointAdjustments = this.getPointAdjustments.bind(this);
      this.renderNoDataIndication = this.renderNoDataIndication.bind(this);
      this.state ={
        data:[],
        isDataLoading: false
      }
    }
  
    componentDidMount() {
      this.getPointAdjustments()
    }

    renderNoDataIndication(props) {
      return (
        <React.Fragment>
          {this.state.isDataLoading &&
            <Spinner animation="border" />
          }
          {!this.state.isDataLoading &&
            <p>No adjustments</p>
          }
        </React.Fragment>
      )
    }
    
    getPointAdjustments() {
      this.setState({ isDataLoading: true }, () =>
        Auth.currentCredentials().then(credentials => {
          const cloudformation = new CloudFormation({
            credentials: Auth.essentialCredentials(credentials),
            region: "eu-west-1"
          });
          const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: "eu-west-1"
          });
  
          var params = {
            StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
          };
          cloudformation.describeStacks(params).promise().then(data => {
            var awsStackOutputs = data.Stacks[0].Outputs
            return awsStackOutputs.filter(obj => {
              return obj.OutputKey === "getPointAdjustmentsArn";
            })[0].OutputValue
          }, error => {
            console.log(error)
          }).then((functionName) => {
            var payload = {
              "year": this.props.yearmonth.getFullYear(),
              "month": this.props.yearmonth.getMonth() + 1,
              "cleenerid": this.props.cleenerid
            }
            var pullParams = {
              FunctionName: functionName,
              InvocationType: 'RequestResponse',
              LogType: 'None',
              Payload: JSON.stringify(payload)
            };
            return lambda.invoke(pullParams).promise();
          }).then(
            result => {
              let payload = JSON.parse(result.Payload);
              if (payload.statusCode === 200) {
                try {
                  let body = JSON.parse(payload.body);
                  //alert(JSON.stringify(body))
                  this.setState({data:body})
                }
                catch(err){
                  MySwal.displaySystemError(payload)
                }
              }
              else {
                MySwal.displaySystemError(payload)
              }
            },
            err => {
              MySwal.displaySystemError(err)
  
            }).finally(() => this.setState({ isDataLoading: false }))
  
        }))
    }
  
    render() {
      const columns = [
        {
          dataField: 'Completion date',
          text: 'Date',
          sort: true
        },
        {
          dataField: 'Total Score',
          text: 'Points',
          align: 'center',
          sort: true,
        },
        {
          dataField: 'Reason',
          text: 'Reason',
          align: 'left',
          sort: true,
        },
        {
          dataField: 'Comment',
          text: 'Comment',
          align: 'left',
          sort: true,
        },
        {
          dataField: 'Created By Name',
          text: 'Created By',
          align: 'center',
          sort: true
        },
        {
          dataField: 'Created On',
          text: 'Created On',
          align: 'center',
          sort: true
        }
      ];
  
      const defaultSorted = [{
        dataField: 'Total Score',
        order: 'desc'
      }];
      
      return (
        <Modal

          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true} onHide={this.props.onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Score adjustments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
            <ToolkitProvider
          keyField='Created On' bootstrap4 data={this.state.data} columns={columns}
          search
          
        >{
          props => (
            <React.Fragment>
            <Form >
              <Row>

                <Col md={6}>
                  <SearchBar autocomplete={"off"} {...props.searchProps} />
                </Col>

              </Row>
            </Form>
            <BootstrapTable noDataIndication={this.renderNoDataIndication}
                    {...props.baseProps}
                    defaultSorted={defaultSorted}
                    striped
                    hover
                    condensed
                  />
                  </React.Fragment>)
          }
</ToolkitProvider>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

  export default ViewPointAdjusmentsModal