import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import Form from 'react-bootstrap/Form';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import LocationHistory from './LocationHistory.js';
import LocationLive from './LocationLive.js';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col'
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';

import * as config from './client-config.js';
import MySwal from './MySwal';



const { ClientConfig } = config;


const MODE_LIVE = 1
const MODE_HISTORY = 2

export default class LocationTracking extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dateOfInterest: new Date(),
      location: "Munich",
      cleenerId: 5,
      mode: MODE_LIVE,
      progressSlider: 0
    }
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleCleenerChange = this.handleCleenerChange.bind(this);
    this.updateCleenerList = this.updateCleenerList.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleProgressSliderChange = this.handleProgressSliderChange.bind(this);
  }


  handleProgressSliderChange = (value) => {
    this.setState({
      progressSlider: value
    });
  };

  componentDidMount() {
    var locationOptions = [];
    var locations = []
    Object.keys(ClientConfig).forEach(client => Object.keys(ClientConfig[client]).forEach(location => locations.push(location)))
    locations = Array.from(new Set(locations));

    locationOptions.push(<option key="blank" value="">Select location</option>)
    locations.forEach(location => locationOptions.push(<option key={location} value={location}>{location}</option>))

    var cleenerOptions = []
    cleenerOptions.push(<option key="blank" value="">Select employee</option>)

    this.setState({
      locationOptions: locationOptions,
      location: locationOptions[0].props.value,
      cleenerOptions: cleenerOptions,
      cleenerId: cleenerOptions[0].props.value,
    });
    this.updateCleenerList()

  }

  updateCleenerList(value) {
    Auth.currentCredentials().then(credentials => {
      const cloudformation = new CloudFormation({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });

      var params = {
        StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
      };
      cloudformation.describeStacks(params).promise().then(data => {
        var awsStackOutputs = data.Stacks[0].Outputs
        return awsStackOutputs.filter(obj => {
          return obj.OutputKey === "getCleenerListArn";
        })[0].OutputValue
      }, error => {
        console.log(error)
      }).then((functionName) => {
        var payload = {
          "location": this.state.location,
        }
        var pullParams = {
          FunctionName: functionName,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify(payload)
        };
        return lambda.invoke(pullParams).promise();
      }).then(
        result => {
          let payload = JSON.parse(result.Payload);
          if (payload.statusCode === 200) {
            try {
              let body = JSON.parse(payload.body);
              let cleenerArray = []
              for (var cleenerRole in body) {
                cleenerArray = cleenerArray.concat(body[cleenerRole])

              }
              var optionsValues = cleenerArray.reduce((obj, item) => {
                obj[item["CleenerID"]] = { label: item['First Name'] + " " + item['Last Name'] + ' (Cleener-' + item["CleenerID"].toString() + ')' }
                return obj
              }, {})
              var options = [];
              options.push(<option key="blank" value="">Select employee</option>)
              Object.keys(optionsValues).forEach(optionValue => options.push(<option key={optionValue} value={optionValue}>{optionsValues[optionValue].label}</option>))
              this.setState({ cleenerOptions: options, cleenerInfoDictionary: optionsValues})

            }
            catch{
              console.error("Fetching cleeners failed")
            }
          }
          else {
            MySwal.displaySystemError(payload.body)
          }
        },
        err => {
          MySwal.displaySystemError(err)

        })

    })
  }


  handleLocationChange(event) {
    let location = event.target.value
    this.setState({ location: location }, this.updateCleenerList);
  }

  handleDateChange(date) {
    this.setState({ dateOfInterest: date });
  }

  handleCleenerChange(event) {
    let cleenerId = event.target.value
    this.setState({ cleenerId: cleenerId });

  }



  render() {



    return (
      <React.Fragment key="Manual Order">
        <div className="App-header">
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col}>
                <ButtonGroup aria-label="Mode">
                  <Button variant={this.state.mode === MODE_LIVE ? "primary" : "secondary"} onClick={() => this.setState({ mode: MODE_LIVE })}>Live</Button>
                  <Button variant={this.state.mode === MODE_HISTORY ? "primary" : "secondary"} onClick={() => this.setState({ mode: MODE_HISTORY })}>History</Button>
                </ButtonGroup>
              </Form.Group>


              <Form.Group as={Col}>
                <Form.Control as="select" onChange={this.handleLocationChange} value={this.state.location}>
                  {this.state.locationOptions}
                </Form.Control>
              </Form.Group>
              {this.state.mode === MODE_HISTORY &&
                <Form.Group as={Col}>
                  <Form.Control as="select" onChange={this.handleCleenerChange} value={this.state.cleenerId}>
                    {this.state.cleenerOptions}
                  </Form.Control>
                </Form.Group>
              }
              {this.state.mode === MODE_HISTORY &&
                <React.Fragment>
                  <Form.Group as={Col}>
                    <Form.Control as={DatePicker}
                      name="Month"
                      format="y-MM-dd"
                      disableClock={true}

                      value={this.state.dateOfInterest}
                      maxDate={new Date()}
                      minDate={new Date(2018, 4, 1)}
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={this.handleDateChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Slider value={this.state.progressSlider} onChange={this.handleProgressSliderChange} />
                  </Form.Group>
                </React.Fragment>

              }

            </Form.Row>
          </Form>
        </div>
        {this.state.mode === MODE_HISTORY && this.state.cleenerId && this.state.location && this.state.dateOfInterest &&
          <LocationHistory cleenerId={this.state.cleenerId} dateOfInterest={this.state.dateOfInterest} progressSlider={this.state.progressSlider}/>
        }
        {this.state.mode === MODE_LIVE && this.state.location &&
          <LocationLive cleenerInfoDictionary={this.state.cleenerInfoDictionary} key={this.state.mode+this.state.location} location={this.state.location} />
        }

      </React.Fragment>
    );
  }
}