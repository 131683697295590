import React from 'react';
import moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import * as queries from './graphql/queries';
import { compose, withProps } from "recompose"
import {  withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { Polyline } from "react-google-maps";

var LPF = require('../node_modules/lpf/lib/index.js');


const API_KEY = "AIzaSyATJvT9Dnm3t4Ff-3i2msxTGKTmGXcjAl8"
const uuidv4 = require('uuid/v4');

const mapEnvironment = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `80vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  //withScriptjs,
  withGoogleMap
);

const HistoryMapLayout = props => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: 51.5074, lng: 0.1278 }}
    ref={props.onMapMounted}
    options={{ streetViewControl: false, fullscreenControl: false }}
  >
    {props.pathCoordinates.map(path =>
      <React.Fragment key={uuidv4()}>
        {props.isMarkerShown && <Marker key={uuidv4()} position={path[0]} icon={"./icons8-map-pin-40-red.png"} />}
        {props.isMarkerShown && <Marker key={uuidv4()} position={path[Math.round((path.length - 1) * (1 - props.progressSlider / 100))]} icon={{
          url: "./cleener_icon.png", anchor: { x: 15, y: 15 }
        }}>    <InfoWindow>
            <div>
              {path[Math.round((path.length - 1) * (1 - props.progressSlider / 100))].deviceName}<br />
              {moment(path[Math.round((path.length - 1) * (1 - props.progressSlider / 100))].timestamp).format("HH:mm")}
            </div>
          </InfoWindow>
        </Marker>}
        {props.isMarkerShown && <Marker key={uuidv4()} position={path[path.length - 1]} icon={"./icons8-map-pin-40-blue.png"} />}
        <Polyline
          path={path}
          geodesic={true}
          options={{
            strokeColor: "#ff2527",
            strokeOpacity: 0.75,
            strokeWeight: 2,
            icons: [
              {
                //icon: lineSymbol,
                offset: "0",
                repeat: "20px"
              }
            ]
          }}
        /></React.Fragment>)}
  </GoogleMap>
);
const HistoryMapComponent = mapEnvironment(HistoryMapLayout);

export default class LocationHistory extends React.Component {
  constructor(props) {
    super(props)
    this.handleMapMounted = this.handleMapMounted.bind(this);

  }

  handleMapMounted = (map) => {
    const { path } = this

    this._map = map
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()

      path.forEach(p => p.forEach(position => {
        bounds.extend(position)
      }))

      this._map.fitBounds(bounds)

    }
  }

  render() {


    var startOfDay = new Date(this.props.dateOfInterest);
    startOfDay.setHours(0, 0, 0, 0);

    var endOfDay = new Date(this.props.dateOfInterest);
    endOfDay.setHours(23, 59, 59, 999);

    let variables = {
      "cleenerId": this.props.cleenerId,
      "limit": 3000,
      "sortDirection": "DESC",
      "timestamp": {
        "between": [moment(startOfDay).unix() * 1e3, moment(endOfDay).unix() * 1e3]
      },
      "filter":
      {
        "accuracy": {
          "lt": 80
        }
      }
    }
    return (
      <Connect query={graphqlOperation(queries.locationByCleenerByTimestamp, variables)}>
        {({ data, loading, errors }) => {
          let listLocations = data.locationByCleenerByTimestamp
          if (Array.isArray(errors) && (errors.length > 0)) {
            return (<h3>Error: {JSON.stringify(errors)}</h3>);
          }
          if (loading || !listLocations || !listLocations.items) return (<center><Spinner animation="border" /></center>);
          if (listLocations.items.length === 0) return (<h3><center>No data</center></h3>);
          if (listLocations.items.length > 0) {

            var uniqueInstallations = [...new Set(listLocations.items.map(location => location.installationId))]
            var paths = []
            uniqueInstallations.forEach((installationId, index) => {
              LPF.smoothing = 0.2;
              let lats = LPF.smoothArray(listLocations.items.filter(location => location.installationId === installationId).map(location => 1e6 * location.latitude))
              let lngs = LPF.smoothArray(listLocations.items.filter(location => location.installationId === installationId).map(location => 1e6 * location.longitude))
              let timestamp = listLocations.items.filter(location => location.installationId === installationId).map(location => location.timestamp)
              let deviceName = listLocations.items.filter(location => location.installationId === installationId).map(location => location.deviceName)
              let path = []
              for (var i = 0; i < lats.length; i++) {
                path.push({ lat: lats[i] / 1e6, lng: lngs[i] / 1e6, timestamp: timestamp[i], deviceName: deviceName[i] })
              }
              paths.push(path)
            })

            this.path = paths
            return (<React.Fragment> <HistoryMapComponent
              isMarkerShown={true}
              pathCoordinates={this.path}
              progressSlider={this.props.progressSlider}
              onMapMounted={this.handleMapMounted}
            />
              {/* {<ListView locations={listLocations.items} />} */}
            </React.Fragment>);
          }
        }
        }
      </Connect>
    )
  }
}
