import React, { Component, useState, useEffect } from 'react';

import * as formik from 'formik';
import moment from 'moment';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Col'
import DateTimePicker from 'react-datetime-picker';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import { graphql } from '@apollo/react-hoc';
import compose from "lodash.flowright";
import * as subscriptions from "./graphql/subscriptions.js";
import * as queries from "./graphql/queries.js";
import gql from 'graphql-tag';
// import { v4 as uuidv4 } from 'uuid';

import * as config from './client-config.js';
import update from 'immutability-helper';
import FieldSpy from './FieldSpy.js';
import FormikPlacesAutocomplete from './PlacesInput.js';
import { Field } from "formik";
import MySwal from './MySwal';
import LocationPOI from './LocationPOI';

import BootstrapTable from 'react-bootstrap-table-next';
import { FaTrash } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { FaRedo } from 'react-icons/fa';
import { withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps"
import { withProps } from "recompose"
import Container from 'react-bootstrap/Container';
import _ from 'lodash'
const uuidv4 = require('uuid/v4');
const { Formik } = formik;
const { ClientConfig } = config;

function unixtimestampToString(timestamp, formatparam) {
    if (formatparam == null) {
        formatparam = "HH:mm"
    }
    return moment.tz(new Date(timestamp), "Europe/Berlin").format(formatparam)
}


const API_KEY = "AIzaSyATJvT9Dnm3t4Ff-3i2msxTGKTmGXcjAl8"

const mapEnvironment = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `50vh` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    //withScriptjs,
    withGoogleMap
);


const LiveMapLayout = props => {
    const [directions, setDirections] = useState(null);

    useEffect(() => {
        let directions = {}
        Object.keys(props.markers).forEach((assignee) => {
            let markers = props.markers[assignee]
            if (markers.length < 2) {
                setDirections(null)
                return
            }
            const directionsService = new window.google.maps.DirectionsService();

            const origin = markers[0]
            const destination = markers[markers.length - 1]
            const waypoints = markers.length >= 3 ? markers.slice(1, markers.length - 1) : []
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.WALKING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        console.log(result)
                        //setDirections(result)
                        setDirections({ ...directions, [assignee]: result })


                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        })


    });
    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
            ref={props.onMapMounted}
            options={{ streetViewControl: false, fullscreenControl: false }}
        >

            {Object.keys(props.markers).map((assignee) => {
                return props.markers[assignee].map(marker =>
                    <Marker key={JSON.stringify(marker.info)} label={marker.info.number} position={marker} >
                        <InfoWindow>
                            <div>
                                {marker.info.plateNumber} {marker.info.carType}<br />
                                {marker.info.cleenerName}<br />
                                {"Planned: " + marker.info.timePlan}<br />
                                {"Booked: " + marker.info.carBooked}<br />
                                {marker.info.location}
                            </div>
                        </InfoWindow>
                    </Marker>)
            })}
            {directions != null &&
                Object.keys(props.markers).map((assignee) =>
                    <DirectionsRenderer
                        directions={directions[assignee]}
                        options={{ suppressMarkers: true }}
                    />)
            }
        </GoogleMap>
    )
}
const LiveMapComponent = mapEnvironment(LiveMapLayout);


class RoutePlanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: "",
            location: "",
        }
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);

    }
    handleClientChange(event) {
        let client = event.target.value
        this.setState({ client: client });

        if (client !== "") {
            var locationOptions = [];
            locationOptions.push(<option key="blank" value="">Select location</option>)
            Object.keys(ClientConfig[client]).forEach(location => locationOptions.push(<option key={location} value={location}>{location[0].toUpperCase() + location.substring(1)}</option>))

            this.setState({ locationOptions: locationOptions, location: locationOptions[0].props.value });
        }
        else {
            this.setState({ locationOptions: [], location: "" });
        }
    }
    handleLocationChange(event) {
        let location = event.target.value
        if ((this.state.client in ClientConfig) && (location in ClientConfig[this.state.client])) {
            this.setState({ location: location });
        }


        //this.setState({ location: location });
    }
    componentDidMount() {
        var clientOptions = [];
        clientOptions.push(<option key="blank" value="">Select client</option>)
        Object.keys(ClientConfig).forEach(client => clientOptions.push(<option key={client} value={client}>{client[0].toUpperCase() + client.substring(1)}</option>))

        var locationOptions = [];

        this.setState({
            clientOptions: clientOptions,
            locationOptions: locationOptions
        });

        if ("client" in this.props) {
            this.setState({ client: this.props.client }, this.handleClientChange({ "target": { "value": this.props.client } }))


            if ("location" in this.props) {
                this.setState({ client: this.props.client, location: this.props.location }, () => {
                    this.handleClientChange({ "target": { "value": this.props.client } })
                    this.handleLocationChange({ "target": { "value": this.props.location } })
                })
            }
        }

    }

    render() {
        return (
            <React.Fragment key="Manual Order">
                <div className="App-header">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Row}>
                                <Form.Control as="select" onChange={this.handleClientChange} value={this.state.client}>
                                    {this.state.clientOptions}
                                </Form.Control>
                            </Form.Group>
                            {this.state.client &&
                                <Form.Group as={Row}>
                                    <Form.Control as="select" onChange={this.handleLocationChange} value={this.state.location}>
                                        {this.state.locationOptions}
                                    </Form.Control>
                                </Form.Group>}
                        </Form.Row>
                    </Form>
                </div>
                {this.state.client && this.state.location &&
                    <RoutePlannerPageWithData client={this.state.client} location={this.state.location} user={this.props.user} />
                }
            </React.Fragment>)
    }
}

class RoutePlannerPage extends Component {
    constructor(props) {
        super(props);
        this.handleStartPlanner = this.handleStartPlanner.bind(this);
        if ((props.client in ClientConfig) && (props.location in ClientConfig[props.client])) {
            let clientConfig = ClientConfig[props.client][props.location]


            this.state = {
                planningInProgress: false,
                schema: clientConfig.schema,
                fields: clientConfig.fields,
                formInitValues: clientConfig.formInitValues,
                showForm: true,
                routeDate: null,
                assignee: null
            }

            this.clientConfig = clientConfig
            this.handleMapMounted = this.handleMapMounted.bind(this);
        }
    }

    handleMapMounted = (map) => {
        const { markers } = this

        this._map = map
        if (map) {
            const bounds = new window.google.maps.LatLngBounds()

            Object.keys(markers).forEach((assignee) => {
                markers[assignee].forEach(marker => {
                    //bounds.extend(marker)
                    bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng + 0.5 / 111 })
                    bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng + 0.5 / 111 })
                    bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng - 0.5 / 111 })
                    bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng - 0.5 / 111 })
                })
            })

            this._map.fitBounds(bounds)
            //map.setZoom(map.getZoom() - 1);
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.data == undefined) return null
        //alert(JSON.stringify(nextProps))
        if (!nextProps.data.loading) {
            // Check for existing subscription
            if (prevState.unsubscribe) {
                // Only unsubscribe/update state if subscription variable has changed
                if (prevState.location === nextProps.location) {
                    return null;
                }
                prevState.unsubscribe();
            }

            return {
                // Subscribe
                unsubscribe: nextProps.data.subscribeToMore({
                    document: gql(subscriptions.onCreateCleenerItinerary),
                    variables: {
                        //param: nextProps.subscriptionParam,
                        city: nextProps.location,
                        sortDirection: "DESC",
                        limit: 1,
                    },
                    updateQuery: (previousResult, { subscriptionData, variables }) => {
                        // Perform updates on previousResult with subscriptionData


                        if (!subscriptionData.data) return previousResult;
                        //alert(JSON.stringify(previousResult.cleenerItineraryByCityByTimestamp.items ))
                        //console.log(subscriptionData)
                        let newCleenerItinerary = _.cloneDeep(subscriptionData.data.onCreateCleenerItinerary);
                        //newCleenerItinerary["id"]="12345"
                        return _.cloneDeep(Object.assign({}, previousResult, {
                            cleenerItineraryByCityByTimestamp: {
                                __typename: previousResult.cleenerItineraryByCityByTimestamp.__typename,
                                nextToken: previousResult.cleenerItineraryByCityByTimestamp.nextToken,
                                items: [newCleenerItinerary, ...previousResult.cleenerItineraryByCityByTimestamp.items]
                            }
                        }));
                    },
                    onError: (error) => { console.error("Itinerary subscribeToMore error:" + error.toString()) }
                }),
                // Store subscriptionParam in state for next update
                subscriptionParam: nextProps.subscriptionParam,
            };
        }

        return null;
    }

    handleStartPlanner() {
        this.setState({ planningInProgress: true }, () =>
            Auth.currentCredentials().then(credentials => {
                const cloudformation = new CloudFormation({
                    credentials: Auth.essentialCredentials(credentials),
                    region: "eu-west-1"
                });
                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: "eu-west-1"
                });

                var params = {
                    StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
                };
                cloudformation.describeStacks(params).promise().then(data => {
                    var awsStackOutputs = data.Stacks[0].Outputs
                    return awsStackOutputs.filter(obj => {
                        return obj.OutputKey === "executeRoutePlanningStepFunctionArn";
                    })[0].OutputValue
                }, error => {
                    console.log(error)
                }).then((functionName) => {


                    // do something here
                    let payload = {
                        "client": this.props.client,
                        "location": this.props.location,
                        "wait_time": 5
                    }
                    let lambdaParams = {
                        FunctionName: functionName,
                        InvocationType: 'RequestResponse',
                        LogType: 'None',
                        Payload: JSON.stringify(payload)
                    };
                    return lambda.invoke(lambdaParams).promise();
                }).then(
                    result => {
                        let payload = JSON.parse(result.Payload);
                        if (parseInt(payload.statusCode) === 200) {

                            MySwal.fire({
                                type: 'success',
                                title: "Success!",
                                text: "Route planning started for " + this.props.client + " " + this.props.location + ".",
                                showConfirmButton: false,
                                timer: 3000,
                            })
                        }
                        else {
                            MySwal.displaySystemError(payload.body)
                        }
                    },
                    err => {
                        MySwal.displaySystemError(err)

                    }).finally(() => { this.setState({ planningInProgress: false }) })

            }));
    }








    render() {
        let columns = [
            {
                dataField: 'markerId',
                text: 'Marker',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            }, {
                dataField: 'timePlan',
                text: 'Time Plan',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'carBooked',
                text: 'Car Booked',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'carType',
                text: 'Car type',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'plateNo',
                text: 'Plate No.',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            },
            {
                dataField: 'location',
                text: 'Location',
                sort: true,
                style: { overflow: "hidden", "textOverflow": "ellipsis" },
                headerStyle: { overflow: "hidden", "textOverflow": "ellipsis" }
            }
        ];


        let dateOptions = []
        var assigneeOptions = []
        let routeCreatedAt = null
        let activitiesListByAssignee = {}
        var markersArrayByAssignee = {}

        if ((this.props.cleenerItinerary != undefined) && ("items" in this.props.cleenerItinerary) && (this.props.cleenerItinerary.items.length > 0)) {
            routeCreatedAt = moment.tz(this.props.cleenerItinerary.items[0].createdAt, "Europe/Berlin").format("YYYY-MM-DD HH:mm:ss")
            //console.log(JSON.stringify(!!this.props.cleenerItinerary.items))
            let routesByDate = JSON.parse(this.props.cleenerItinerary.items[0].routes)
            let cardsByDate = JSON.parse(this.props.cleenerItinerary.items[0].cards)
            //alert(JSON.stringify(route))
            for (const date in routesByDate) {
                dateOptions.push(date)
            }

            if (this.state.routeDate && this.state.routeDate in routesByDate) {
                let date = this.state.routeDate
                var markerLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                //let route = routesByDate[date].solution.routes


                for (const route in routesByDate[date].solution.routes) {
                    let assignee = routesByDate[date].solution.routes[route].vehicle_id
                    assigneeOptions.push(JSON.parse(assignee))
                }

                let assignee = assigneeOptions[0].trello_username; //"gabor170" //this.props.user.trelloName
                assigneeOptions.forEach(assignee => {
                    var activitiesList = []
                    var markersArray = []
                    for (const route in routesByDate[date].solution.routes) {
                        let activities = routesByDate[date].solution.routes[route].activities
                        //alert(JSON.stringify(routesByDate[date].solution.routes[route].vehicle_id))
                        let vehicle_id = JSON.parse(routesByDate[date].solution.routes[route].vehicle_id)
                        if (vehicle_id["trello_username"] == assignee.trello_username) {

                            let transportTime = "transport time: " + Number((routesByDate[date].solution.routes[route]['transport_time'] / 3600).toFixed(1)) + "h"
                            let transportDistance = "distance: " + Number((routesByDate[date].solution.routes[route]['distance'] / 1000).toFixed(1)) + "km"

                            var activityCntr = 0


                            for (const activity in activities) {
                                if (activities[activity].type == 'service') {
                                    let rowData = []
                                    //alert(JSON.stringify(cardsByDate))
                                    var card = cardsByDate[date][activities[activity].id]
                                    //alert(JSON.stringify(cardsByDate))
                                    //rowData.push(card['OrderID'])
                                    rowData.push(markerLabels[assigneeOptions.map(item => item.trello_username).indexOf(assignee.trello_username)] + (activityCntr + 1).toString())
                                    rowData.push(unixtimestampToString(activities[activity].arr_time * 1000) + " - " + unixtimestampToString(activities[activity].end_time * 1000))

                                    rowData.push(moment(card['ServiceDateFrom']).format("HH:mm") + " - " + moment(card['ServiceDateTill']).format("HH:mm"))
                                    rowData.push(card['Fahrzeuginformation'])
                                    rowData.push(card['Fahrzeug'])
                                    rowData.push(activities[activity].address.location_id)
                                    //rowData.push(markerLabels[assigneeIndex] + (activityCntr + 1))


                                    activitiesList.push({
                                        markerId: markerLabels[assigneeOptions.map(item => item.trello_username).indexOf(assignee.trello_username)] + (activityCntr + 1).toString(),
                                        timePlan: unixtimestampToString(activities[activity].arr_time * 1000) + " - " + unixtimestampToString(activities[activity].end_time * 1000),
                                        carBooked: moment(card['ServiceDateFrom']).format("HH:mm") + " - " + moment(card['ServiceDateTill']).format("HH:mm"),
                                        carType: card['Fahrzeuginformation'],
                                        plateNo: card['Fahrzeug'],
                                        location: activities[activity].address.location_id

                                    })
                                    markersArray.push({
                                        lat: activities[activity].address.lat,
                                        lng: activities[activity].address.lon,
                                        info: {
                                            number: rowData[0],
                                            timePlan: rowData[1],
                                            carBooked: rowData[2],
                                            carType: rowData[3],
                                            plateNumber: rowData[4],
                                            location: rowData[5],
                                            cleenerName: assignee["First Name"]+" "+assignee["Last Name"]+" (Cleener-"+assignee.CleenerID+")"
                                        }
                                    })
                                    activityCntr += 1
                                }
                            }
                        }
                    }
                    activitiesListByAssignee[assignee.trello_username] = activitiesList
                    markersArrayByAssignee[assignee.trello_username] = markersArray
                })
            }
        }
        this.markers = markersArrayByAssignee//this.state.assignee in markersArrayByAssignee ? markersArrayByAssignee[this.state.assignee] : []
        return (

            <Container fluid>

                <div className='mt-3'>
                    <Form>
                        <Form.Row inline className="justify-content-md-center">
                            {dateOptions.length > 0 &&
                                <Form.Group inline as={Col} lg="1">
                                    {/* <Form.Control as="select" onChange={(event)=>this.setState({routeDate:event.target.value})} value={this.state.routeDate}>
                                    {dateOptions.map(date => <option key={date} value={date}>{date}</option>)}
}
                                </Form.Control> */}
                                
                                    <Selector key={routeCreatedAt} valueOptions={dateOptions} value={this.state.routeDate} setValue={(value) => this.setState({ routeDate: value })} />
                               
                                    
                                </Form.Group >
                            }
<Col lg="1">

<Button onClick={this.handleStartPlanner}><FaRedo/>&nbsp;Replan</Button>
</Col>
                        </Form.Row>
                    </Form>
                </div>




                {this.props.cleenerItinerary &&
                    <LiveMapComponent
                        markers={this.markers}
                        onMapMounted={this.handleMapMounted}
                    />
                }
                 {routeCreatedAt && <p className="text-center">{"Last updated: " + routeCreatedAt}</p>}

<Form>
<hr/>
<Form.Row>

    {assigneeOptions.length > 0 &&
        <Form.Group as={Col}>
            {/* <Form.Control as="select" onChange={(event)=>this.setState({assignee:event.target.value})} value={this.state.assignee}>
            {assigneeOptions.map(assignee => <option key={assignee.trello_username} value={assignee.trello_username}>{assignee.trello_username}</option>)}
            </Form.Control> */}
            <AssigneeSelector key={JSON.stringify(assigneeOptions)} valueOptions={assigneeOptions} value={this.state.assignee} setValue={(value) => this.setState({ assignee: value })} />

        </Form.Group>
    }

</Form.Row>
</Form>

                {this.state.assignee in markersArrayByAssignee &&
                    <BootstrapTable striped hover condensed keyField='id' data={activitiesListByAssignee[this.state.assignee]} columns={columns} noDataIndication={<p>No routes.</p>} />
                }
               
            </Container>

        );
    }
}

class Selector extends Component {
    componentDidMount() {
        if (Array.isArray(this.props.valueOptions) && this.props.valueOptions.length > 0) {
            this.props.setValue(this.props.valueOptions[0]);
        }
    }

    handleChange = event => {
        this.props.setValue(event.target.value);
    };

    render() {
        return (
            <Form.Control as="select" onChange={this.handleChange} value={this.props.value}>
                {this.props.valueOptions.map(value => <option key={value} value={value}>{value}</option>)}
            </Form.Control>
        )
    }
}

class AssigneeSelector extends Component {
    componentDidMount() {
        if (Array.isArray(this.props.valueOptions) && this.props.valueOptions.length > 0 && "trello_username" in this.props.valueOptions[0]) {

            this.props.setValue(this.props.valueOptions[0].trello_username);
        }
    }

    handleChange = event => {
        this.props.setValue(event.target.value);
    };

    render() {
        return (
            <Form.Control as="select" onChange={this.handleChange} value={this.props.value}>
                {this.props.valueOptions.map(value => <option key={value.trello_username} value={value.trello_username}>{value["First Name"]+" "+value["Last Name"]+" (Cleener-"+value.CleenerID+")"}</option>)}
            </Form.Control>
        )
    }
}


const RoutePlannerPageWithData = compose(
    graphql(gql(queries.cleenerItineraryByCityByTimestamp),
        {
            skip: props => props.location == null,
            options: props => ({
                variables: {
                    city: props.location,
                    sortDirection: "DESC",
                    limit: 1
                },
                fetchPolicy: "cache-and-network",
                // pollInterval:0,
                // errorPolicy:"ignore"
            }),
            props: (props) => {
                let { data: { cleenerItineraryByCityByTimestamp: event, loading, error } } = props
                return {
                    data: props.data,
                    cleenerItinerary: event,
                    loadingCleenerItinerary: loading,
                    errorCleenerItinerary: error
                }
            },
        })
)(RoutePlannerPage);

export default RoutePlanner