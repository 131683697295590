import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-date-picker';
import MySwal from './MySwal';
import * as config from './client-config.js';
import moment from 'moment';
import { FaFileDownload } from 'react-icons/fa';
const { SearchBar } = Search;
const { ClientConfig } = config;

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Button variant="secondary" onClick={handleClick}><FaFileDownload/>&nbsp;Download</Button>
  );
};


class CleenersTimesheet extends Component {
  constructor(props) {
    super(props);
    
    var monthOptions = [];
    monthOptions.push(<option key="blank" value="">Select month</option>)
    for (var i = 1; i < 13; i++) {
      monthOptions.push(<option key={i.toString()} value={i.toString()}>{i.toString()}</option>)
    }

    var yearOptions = [];
    yearOptions.push(<option key="blank" value="">Select year</option>)
    yearOptions.push(<option key="2019" value="2019">2019</option>)
    yearOptions.push(<option key="2018" value="2018">2018</option>)
    
    this.state = {
      cleenersTimesheet: [],
      isDataLoading: false,
      yearmonth: new Date(),
      selectedCleenerID: null,
      yearOptions: yearOptions,
      location: "",
      monthOptions: monthOptions,
      interval: monthOptions[0].props.value,
    }
    this.adjustPointsModal = React.createRef();
    this.renderNoDataIndication = this.renderNoDataIndication.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.getCleenersTimesheet = this.getCleenersTimesheet.bind(this);
  }

  componentDidMount() {
    var locationOptions = [];
    var locations = []
    Object.keys(ClientConfig).forEach(client => Object.keys(ClientConfig[client]).forEach(location => locations.push(location)))
    locations = Array.from(new Set(locations));

    locationOptions.push(<option key="blank" value="">Select location</option>)
    locations.forEach(location => locationOptions.push(<option key={location} value={location}>{location}</option>))
    
    this.setState({ 
      locationOptions: locationOptions, 
      location: locationOptions[0].props.value,
    })

    this.getCleenersTimesheet();
  }

  renderNoDataIndication(props) {
    return (
      <React.Fragment>
        {this.state.isDataLoading &&
          <Spinner animation="border" />
        }
        {!this.state.isDataLoading &&
          <p>No data</p>
        }
      </React.Fragment>
    )
  }
  getCleenersTimesheet() {
    if(!this.state.location || !this.state.yearmonth) {
      return
    }

    this.setState({ isDataLoading: true, cleenersTimesheet: [], selectedCleenerID: null }, () =>
      Auth.currentCredentials().then(credentials => {
        const cloudformation = new CloudFormation({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });

        var params = {
          StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
        };
        cloudformation.describeStacks(params).promise().then(data => {
          var awsStackOutputs = data.Stacks[0].Outputs
          return awsStackOutputs.filter(obj => {
            return obj.OutputKey === "getCleenerHoursArn";
          })[0].OutputValue
        }, error => {
          console.log(error)
        }).then((functionName) => {
          var payload = {
            "location":this.state.location,
            "fromDate":  moment(this.state.yearmonth).startOf('month').format('YYYY-MM-DD'),
            "untilDate": moment(this.state.yearmonth).add(1,'months').startOf('month').format('YYYY-MM-DD'),
          }
          var pullParams = {
            FunctionName: functionName,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: JSON.stringify(payload)
          };
          return lambda.invoke(pullParams).promise();
        }).then(
          result => {
            let payload = JSON.parse(result.Payload);
            if (payload.statusCode === 200) {
              try {
                let body = JSON.parse(payload.body);
                this.setState({ cleenersTimesheet: body })
              }
              catch(err){
                MySwal.displaySystemError(payload)
              }
            }
            else {
              MySwal.displaySystemError(payload)
            }
          },
          err => {
            MySwal.displaySystemError(err)

          }).finally(() => this.setState({ isDataLoading: false }))

      }))
  }
  handleLocationChange(event) {
    let location = event.target.value
    this.setState({ location: location }, this.getCleenersTimesheet);
  }

  handleYearMonthChange(date) {
    this.setState({ yearmonth: date }, this.getCleenersTimesheet);
  }

  render() {
    const columns = [
      {
        dataField: 'date',
        text: 'Date',
        sort: true
      },
      {
        dataField: 'cleenerName',
        text: 'Cleener Name',
        sort: true
      },
      {
        dataField: 'checkinTime',
        text: 'Check-in',
        sort: false
      },
      {
        dataField: 'checkoutTime',
        text: 'Check-out',
        sort: false
      },
      {
        dataField: 'workingTimeString',
        text: 'Working Hours',
        sort: false
      }];
      
    const defaultSorted = [{
      dataField: 'date',
      order: 'desc'
    }];


    return (
      <Container>
        <ToolkitProvider
          keyField='Cleaner Name' bootstrap4 data={this.state.cleenersTimesheet} columns={columns}
          search
          exportCSV
        >
          {
            props => (
              <React.Fragment>
                <Form >
                  <Row>
                  <Col>
                      <Form.Control as="select"  onChange={this.handleLocationChange} value={this.state.location}>
                        {this.state.locationOptions}
                      </Form.Control>
                      </Col>
                    

                    <Col>
                      <Form.Control as={DatePicker}
                        name="Month"
                        format="y-MM"
                        disableClock={true}
                        maxDetail="year"
                        value={this.state.yearmonth}
                        maxDate={new Date()}
                        minDate={new Date(2018, 4, 1)}
                        calendarIcon={null}
                        clearIcon={null}
                        onChange={this.handleYearMonthChange}
                      />

                    </Col>
                    <Col>
                      <SearchBar autocomplete={"off"} {...props.searchProps} />
                    </Col>
                    <Col>
                      <MyExportCSV {...props.csvProps}>Export</MyExportCSV>
                    </Col>

                  </Row>
                </Form>
                <hr />
                {this.state.yearmonth &&
                  <BootstrapTable noDataIndication={this.renderNoDataIndication}
                    {...props.baseProps}
                    defaultSorted={defaultSorted}
                    striped
                    hover
                    condensed
                  />}
              </React.Fragment>
            )
          }
        </ToolkitProvider>


      </Container>)
  }
}

export default CleenersTimesheet;