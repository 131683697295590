import React, { Component } from 'react';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { BrowserRouter as Router, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import OAuthButton from './OAuthButton';
import ManualOrder from './ManualOrder';
import RoutePlanner from './RoutePlanner';
import LocationTracking from './LocationTracking';
import CleenersQuota from './CleenersQuota';
import CleenersTimesheet from './CleenersTimesheet';
import CompletedOrders from './CompletedOrders';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-parameters'; // Amplify configuration
import Jumbotron from 'react-bootstrap/Jumbotron';
import { ApolloProvider } from '@apollo/react-common';
import { ApolloLink } from 'apollo-link'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();
// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
// if (path) {
//   if("REACT_APP_ROUTER_BASENAME" in process.env) {
//   history.replace("/"+process.env.REACT_APP_ROUTER_BASENAME+path);
// }
// else{
//   history.replace(path);
// }
// }

Amplify.configure(awsconfig);
const config = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(config),
    createSubscriptionHandshakeLink(config)
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

function Index(props) {
  return (
    <Jumbotron>
      {props.user &&
        <React.Fragment>
          <h2>Hello {props.user.attributes.given_name}</h2>
          <p>
            You've logged in successfuly. Now go ahead and create something amazing.<span role="img" aria-label="love">💖</span></p>
          </React.Fragment>}

          {!props.user &&
        <React.Fragment>
          <h2>Hello</h2>
          <p>
            Please sign-in with your CleenUp account to get started. <span role="img" aria-label="Go!">🏁</span></p>
          </React.Fragment>}
    </Jumbotron>

  )

}


class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          this.setState({ authState: 'signedIn', authData: data.payload.data });
          Auth.currentAuthenticatedUser().then(user => {
            console.log(user);
            this.setState({ user: user });
          }).catch(e => {
            console.log(e);
            this.setState({ authState: 'signIn' });
          });

          break;
        case 'signIn_failure':
          this.setState({ authState: 'signIn', authData: null, authError: data.payload.data });
          break;
        default:
          break;
      }
    });
    this.state = {
      authState: 'loading',
      authData: null,
      authError: null
    }
  }

  componentDidMount() {
    console.log('on component mount');
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user);
      this.setState({ authState: 'signedIn', user: user });
    }).catch(e => {
      console.log(e);
      this.setState({ authState: 'signIn' });
    });
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({ authState: 'signIn' });
    }).catch(e => {
      console.log(e);
    });
  }

  render() {
    const { authState } = this.state;
    return (
      <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
        <div className="App">

          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
            <Navbar.Brand><img height="42" width="42" src="./cleenup_icon.png" alt="CleenUp icon"/>&nbsp; Operator</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {authState === 'signedIn' &&
                <Nav className="mr-auto">
                  <LinkContainer to="/manualorder/"><Nav.Link>Manual Order</Nav.Link></LinkContainer>
                  <LinkContainer to="/completedorders"><Nav.Link>Completed Orders</Nav.Link></LinkContainer>
                  <LinkContainer to="/cleenersquota"><Nav.Link>Cleeners Quota</Nav.Link></LinkContainer>
                  <LinkContainer to="/cleenerstimesheet"><Nav.Link>Timesheets</Nav.Link></LinkContainer>
                  <LinkContainer to="/locationtracking"><Nav.Link>Location Tracking</Nav.Link></LinkContainer>
                  <LinkContainer to="/routeplanner"><Nav.Link>Route Planner</Nav.Link></LinkContainer>
                </Nav>}
              {authState !== 'signedIn' &&
                <Nav className="mr-auto"></Nav>
              }
              <Nav>
                {authState === 'loading' && (<Nav.Link>loading...</Nav.Link>)}
                {authState === 'signIn' && <OAuthButton />}
                {authState === 'signedIn' && <Nav.Link onClick={this.signOut}>Sign out</Nav.Link>}

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Route path="/" exact render={() => <Index user={this.state.user} />} />
          <Route exact path="/manualorder/" render={(props) => <ManualOrder user={this.state.user} />} />
          <Route exact path="/manualorder/:client/" render={({ match }) => <ManualOrder client={match.params.client} user={this.state.user} />} />
          <Route exact path="/manualorder/:client/:location" render={({ match }) => <ManualOrder client={match.params.client} location={match.params.location} user={this.state.user} />} />
          <Route path="/cleenersquota/" render={(props) => <CleenersQuota user={this.state.user} />} />
          <Route path="/cleenerstimesheet/" render={(props) => <CleenersTimesheet user={this.state.user} />} />
          <Route path="/completedorders/" component={CompletedOrders} />
          <Route path="/locationtracking/" component={LocationTracking} />
          <Route path="/routeplanner/" component={RoutePlanner} />
        </div>
      </Router>
    );
  }
}

const WithProvider = (props) => (
  <ApolloProvider client={client}>
  
  <App {...props}/>
  
  
  </ApolloProvider>
);

export default WithProvider