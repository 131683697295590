import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import DatePicker from 'react-date-picker';
import MySwal from './MySwal';
import moment from 'moment';


class AdjustPointsModal extends Component {
    constructor(props) {
      super(props);
      this.defaultState = {
        show: false,
        reasonOptions: [],
        reason: "",
        comment: "",
        amount: "",
        eventDate: new Date(),
        saveInProgress: false,
        dialogTitle: null,
        isAdd: true
      }
      this.state = this.defaultState
      this.handleClose = this.handleClose.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
  
      this.addPointsReasonOptions = [];
      this.addPointsReasonOptions.push(<option key="blank" value="">Select reason</option>)
      this.addPointsReasonOptions.push(<option key="ANNUAL_LEAVE" value="Annual leave">Annual leave</option>)
      this.addPointsReasonOptions.push(<option key="SICK_LEAVE" value="Sick leave">Sick leave</option>)
      this.addPointsReasonOptions.push(<option key="REWARD" value="Reward">Reward</option>)
      this.addPointsReasonOptions.push(<option key="EXTREME_WEATHER" value="Extreme weather">Extreme weather</option>)
      this.addPointsReasonOptions.push(<option key="CLIENT_IT_DOWN" value="Client IT downtime">Client IT downtime</option>)
      this.addPointsReasonOptions.push(<option key="CLEENUP_IT_DOWN" value="CleenUp IT downtime">CleenUp IT downtime</option>)
      this.addPointsReasonOptions.push(<option key="OTHER_JUSTIFIED" value="Other justified absence">Other justified absence</option>)
      this.addPointsReasonOptions.push(<option key="OPERATOR_ERROR" value="Operator error">Operator error</option>)
  
      this.removePointsReasonOptions = [];
      this.removePointsReasonOptions.push(<option key="blank" value="">Select reason</option>)
      this.removePointsReasonOptions.push(<option key="FAILED_QUALITY_CHECK" value="Failed quality check">Failed quality check</option>)
      this.removePointsReasonOptions.push(<option key="DISCIPLINARY_ACTION" value="Disciplinary action">Disciplinary action</option>)
      this.removePointsReasonOptions.push(<option key="OPERATOR_ERROR" value="Operator error">Operator error</option>)

      this.pointsAmountOptions = [];
      this.pointsAmountOptions.push(<option key="blank" value="">Select amount</option>)
      this.pointsAmountOptions.push(<option key="15" value="15">15 points (15 mins)</option>)
      this.pointsAmountOptions.push(<option key="30" value="30">30 points (30 mins)</option>)
      this.pointsAmountOptions.push(<option key="60" value="60">60 points (1 hour)</option>)
      this.pointsAmountOptions.push(<option key="120" value="120">120 points (2 hours)</option>)
      this.pointsAmountOptions.push(<option key="180" value="180">180 points (3 hours)</option>)
      this.pointsAmountOptions.push(<option key="240" value="240">240 points (4 hours)</option>)
      this.pointsAmountOptions.push(<option key="480" value="480">480 points (1 day)</option>)
      this.pointsAmountOptions.push(<option key="960" value="960">960 points (2 days)</option>)
      this.pointsAmountOptions.push(<option key="1440" value="1440">1440 points (3 days)</option>)
      this.pointsAmountOptions.push(<option key="1920" value="1920">1920 points (4 days)</option>)
      this.pointsAmountOptions.push(<option key="2400" value="2400">2400 points (5 days)</option>)
  
    }
  
    componentDidMount() {
      this.setState({ reasonOptions: this.addPointsReasonOptions, dialogTitle: "Add points" })
    }
    handleClose = () => this.setState(this.defaultState);
    
    handleShow(action) {
      if (action === "add") {
        this.setState({ isAdd: true, reasonOptions: this.addPointsReasonOptions, show: true, dialogTitle: "Add points" })
      }
      else if (action === "remove") {
        this.setState({ isAdd: false, reasonOptions: this.removePointsReasonOptions, show: true, dialogTitle: "Reduce points" })
      }
    }
  
    handleChange(event) {
      this.setState({ [event.target.name]: event.target.value })
  
    }
  
    handleDateChange(date) {
      this.setState({ eventDate: date });
    }
  
    handleSave() {
      this.setState({ saveInProgress: true }, () =>
        Auth.currentCredentials().then(credentials => {
          const cloudformation = new CloudFormation({
            credentials: Auth.essentialCredentials(credentials),
            region: "eu-west-1"
          });
          const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: "eu-west-1"
          });
  
          var params = {
            StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
          };
          cloudformation.describeStacks(params).promise().then(data => {
            var awsStackOutputs = data.Stacks[0].Outputs
            return awsStackOutputs.filter(obj => {
              return obj.OutputKey === "adjustCleenerPointsArn";
            })[0].OutputValue
          }, error => {
            console.log(error)
          }).then((functionName) => {
            var payload = {
              "cleener_id": this.props.cleenerid,
              "event_date": moment(this.state.eventDate).format("YYYY-MM-DD"),
              "created_by_name": this.props.user.attributes.given_name+' '+this.props.user.attributes.family_name,
              "created_by_email": this.props.user.attributes.email,
              "created_on": moment().format("YYYY-MM-DD HH:mm:ss"),
              "reason": this.state.reason,
              "amount": (this.state.isAdd === true ? this.state.amount : (-this.state.amount).toString()),
              "comment": this.state.comment
            }
            var pullParams = {
              FunctionName: functionName,
              InvocationType: 'RequestResponse',
              LogType: 'None',
              Payload: JSON.stringify(payload)
            };
            return lambda.invoke(pullParams).promise();
          }).then(
            result => {
              let payload = JSON.parse(result.Payload);
              if (payload.statusCode === 200) {
                try {
                  // body not used: let body = JSON.parse(payload.body);
                  this.props.getcleenersquota()
                  this.handleClose()
                }
                catch(err){
                  this.handleClose()
                  MySwal.displaySystemError(err)
                }
              }
              else {
                this.handleClose()
                MySwal.displaySystemError(payload)
              }
            },
            err => {
              this.handleClose()
              MySwal.displaySystemError(err)
  
            }).finally(() => this.setState({ saveInProgress: false }))
  
        }))
    }
  
    render() {
      return (
        <Modal

          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show} onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.dialogTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
            <Form>
            <Form.Group > 
                <Form.Label>Date</Form.Label>
                <Form.Control as={DatePicker}
                  name="eventDate"
                  format="y-MM-dd"
                  disableClock={true}
                  value={this.state.eventDate}
                  isOpen={false}
                  clearIcon={null}
                  required={true}
                  disableCalendar={true}
                  onChange={this.handleDateChange}
                />
              </Form.Group>
              <Form.Group > 
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="select"
                  name="reason"
                  value={this.state.reason}
                  onChange={this.handleChange}
                  disabled={this.state.saveInProgress}
                > {this.state.reasonOptions}
                </Form.Control>
                </Form.Group>
              <Form.Group > 
                <Form.Label>Points</Form.Label>
                <Form.Control
                  as="select"
                  name="amount"
                  value={this.state.amount}
                  onChange={this.handleChange}
                  disabled={this.state.saveInProgress}
                >   {this.pointsAmountOptions}
                </Form.Control>
                </Form.Group>
              <Form.Group > 
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  type="text"
                  name="comment"
                  value={this.state.comment}
                  onChange={this.handleChange}
                  disabled={this.state.saveInProgress}
                >
                </Form.Control>
              </Form.Group>
            
            </Form>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSave} disabled={!(this.state.reason && this.state.amount && this.state.eventDate)}>
              {this.state.saveInProgress &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

  export default AdjustPointsModal