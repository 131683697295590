import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Col from 'react-bootstrap/Col'
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';

import MySwal from './MySwal';
import CustomMapControl from './CustomMapControl';
//import * as subscriptions from './graphql/subscriptions';
import { compose, withProps } from "recompose"
import { withGoogleMap, SearchBox, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import Select from 'react-select'
import _ from 'lodash'
import Spinner from 'react-bootstrap/Spinner';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

class MyComponent extends React.Component {
  render() {    
    return (
      <Select isMulti options={this.props.options} onChange={this.props.handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        value={this.props.options.filter(option => this.props.selectedValues.includes(option.value)  )}
      />)
  }
}


const API_KEY = "AIzaSyATJvT9Dnm3t4Ff-3i2msxTGKTmGXcjAl8"

const mapEnvironment = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`,
    loadingElement: <div style={{ height: `400px` }} />,
    containerElement: <div style={{ height: `440px` }} />,
    mapElement: <div style={{ height: `400px` }} />,
  }),
  //withScriptjs,
  withGoogleMap
);


const LiveMapLayout = props => {
  const [openInfoWindowMarkerIds, setOpenInfoWindowMarkerIds] = useState([]);

  return (<GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -34.397, lng: 150.644 }}
    ref={props.onMapMounted}
    options={{ streetViewControl: false,mapTypeControl:false, fullscreenControl: false }}
  >
   

   {_.isEqual(props.markers.map(marker => marker.id), openInfoWindowMarkerIds) ?
      <Button size="sm" variant="light" onClick={()=>{
          setOpenInfoWindowMarkerIds([])
      }}><FaEyeSlash/>&nbsp;Hide labels</Button>
      :
      <Button size="sm" variant="light" onClick={()=>{
        let allMarkers = props.markers.map(marker => marker.id)
        setOpenInfoWindowMarkerIds(allMarkers)

      }}><FaEye/>&nbsp;Show labels</Button>
    }

    {props.markers.map(marker =>
      <Marker key={marker.id} position={marker} icon={"./car_icon.png"} onClick={() => {
        if (!openInfoWindowMarkerIds.includes(marker.id)) {
          setOpenInfoWindowMarkerIds(openInfoWindowMarkerIds.concat([marker.id]))
        }
        else {
          setOpenInfoWindowMarkerIds(openInfoWindowMarkerIds.filter(id=>id!==marker.id))
        }
        }}>
        {openInfoWindowMarkerIds.includes(marker.id) &&
        <InfoWindow onCloseClick={()=>setOpenInfoWindowMarkerIds(openInfoWindowMarkerIds.filter(id=>id!==marker.id))}>
          
          <Button size="sm" variant="link" onClick={()=>
            {
              props.setFieldValue("carType",marker.carType);
              props.setFieldValue("carOpenLink",marker.URL);
              props.setFieldValue("plateNumber",marker.plateNumber);
            }}>
            {marker.plateNumber}
            </Button>
        </InfoWindow>}
      </Marker>)}

  </GoogleMap>)
};

const LiveMapComponent = mapEnvironment(LiveMapLayout);




export default class LocationPOI extends React.Component {
  constructor(props) {
    super(props)
    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.state = { pointOfInterestList: [], selectedValues: [] }
    this.handleChange = this.handleChange.bind(this);

  }


  getPOIInfo() {
    this.setState({ isDataLoading: true }, () =>
      Auth.currentCredentials().then(credentials => {
        const cloudformation = new CloudFormation({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });

        var params = {
          StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
        };
        cloudformation.describeStacks(params).promise().then(data => {
          var awsStackOutputs = data.Stacks[0].Outputs
          return awsStackOutputs.filter(obj => {
            return obj.OutputKey === "getPOIListArn";
          })[0].OutputValue
        }, error => {
          console.log(error)
        }).then((functionName) => {
          var payload = {
            "client": this.props.client,
            "location": this.props.location,
          }
          var pullParams = {
            FunctionName: functionName,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: JSON.stringify(payload)
          };
          return lambda.invoke(pullParams).promise();
        }).then(
          result => {
            let payload = JSON.parse(result.Payload);
            if (payload.statusCode === 200) {
              try {
                let body = JSON.parse(payload.body);
                //alert(JSON.stringify(body))
                this.setState({ pointOfInterestList: body, selectedValues:body.filter(item=>item["Active Indicator"]===1).map(item=>item["POI ID"])} )
              }
              catch (err) {
                MySwal.displaySystemError(payload)
              }
            }
            else {
              MySwal.displaySystemError(payload)
            }
          },
          err => {
            MySwal.displaySystemError(err)

          }).finally(() => this.setState({ isDataLoading: false }))

      }))
  }

  componentDidMount() {
    this.getPOIInfo()
  }
  handleMapMounted = (map) => {
    let markers =  this.state.pointOfInterestList.filter(item => this.state.selectedValues.includes(item["POI ID"])).map(item => {
      return {
        lat: item["Parking Spot Address Latitude"],
        lng: item["Parking Spot Address Longitude"]
      }
    })

    this._map = map
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()

      markers.forEach((marker) => {
        //bounds.extend(marker)
        bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng + 0.5 / 111 })
        bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng + 0.5 / 111 })
        bounds.extend({ lat: marker.lat - 0.5 / 111, lng: marker.lng - 0.5 / 111 })
        bounds.extend({ lat: marker.lat + 0.5 / 111, lng: marker.lng - 0.5 / 111 })
      })

      this._map.fitBounds(bounds)
      //map.setZoom(map.getZoom() - 1);
    }
  }

  handleChange(items, action) { 
    let item_list = []
    if (items!==null && items.length>0) {
      item_list = items
    }

    this.setState({selectedValues:item_list.map(item=>item.value)}, () =>
    Auth.currentCredentials().then(credentials => {
      const cloudformation = new CloudFormation({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });

      var params = {
        StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
      };
      cloudformation.describeStacks(params).promise().then(data => {
        var awsStackOutputs = data.Stacks[0].Outputs
        return awsStackOutputs.filter(obj => {
          return obj.OutputKey === "setPOIStatusArn";
        })[0].OutputValue
      }, error => {
        console.log(error)
      }).then((functionName) => {
        var payload = {
          "client": this.props.client,
          "location": this.props.location,
          "poi_list": item_list.map(item=>item.value)
        }
        var pullParams = {
          FunctionName: functionName,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify(payload)
        };
        return lambda.invoke(pullParams).promise();
      }).then(
        result => {
          let payload = JSON.parse(result.Payload);
          if (payload.statusCode === 200) {
            try {
              //let body = JSON.parse(payload.body);
              this.handleMapMounted(this._map)
              //alert(JSON.stringify(body))
              //this.setState({ pointOfInterestList: body, selectedValues:body.filter(item=>item["Active Indicator"]===1).map(item=>item["POI ID"])} )
            }
            catch (err) {
              MySwal.displaySystemError(payload)
            }
          }
          else {
            MySwal.displaySystemError(payload)
          }
        },
        err => {
          MySwal.displaySystemError(err)

        }).finally(() => this.setState({ isDataLoading: false }))

    }))



  }
  

  render() {



    return (<React.Fragment>
      {this.state.pointOfInterestList.length>0 ?
      <Form.Group as={Col} controlId={this.props.controlId} >
        {/* <Form.Label>{this.props.label}</Form.Label> */}
      <LiveMapComponent
        setFieldValue={this.props.setFieldValue}
        markers={this.state.pointOfInterestList.filter(item => this.state.selectedValues.includes(item["POI ID"]) === true).map(item => {
          return {
            id: item["POI ID"].toString(),
            plateNumber: item["Platenumber"],
            carType: item["Model"],
            URL: item["URL"],
            parkingSpotAddress: item["Parking Spot Address"],
            lat: item["Parking Spot Address Latitude"],
            lng: item["Parking Spot Address Longitude"]
          }
        })}
        onMapMounted={this.handleMapMounted}
      />
      
      <MyComponent options={this.state.pointOfInterestList.map(item => {
        return {
          value: item["POI ID"],
          label: item["Platenumber"],
        }
      })} selectedValues={this.state.selectedValues} handleChange={this.handleChange}/>
      <hr/>
      </Form.Group>
      :
      <Jumbotron>
      <center><Spinner animation="border" /><br/>Loading map</center>
      </Jumbotron>
      }

    </React.Fragment>);

  }
}
