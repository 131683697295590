import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
MySwal.displaySystemError = (err) => {
    console.log(err)
    MySwal.fire({
        type: 'error',
        title: "Something is wrong",
        text: "A system error occured, please send an email to it@cleenup.com with the details.",
        showConfirmButton: true,
        onClose: function () {
        }
    });
}

export default MySwal