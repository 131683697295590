/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPreorder = /* GraphQL */ `
  mutation CreatePreorder($input: CreatePreorderInput!) {
    createPreorder(input: $input) {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const updatePreorder = /* GraphQL */ `
  mutation UpdatePreorder($input: UpdatePreorderInput!) {
    updatePreorder(input: $input) {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const deletePreorder = /* GraphQL */ `
  mutation DeletePreorder($input: DeletePreorderInput!) {
    deletePreorder(input: $input) {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const createCleenerAvailability = /* GraphQL */ `
  mutation CreateCleenerAvailability($input: CreateCleenerAvailabilityInput!) {
    createCleenerAvailability(input: $input) {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateCleenerAvailability = /* GraphQL */ `
  mutation UpdateCleenerAvailability($input: UpdateCleenerAvailabilityInput!) {
    updateCleenerAvailability(input: $input) {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteCleenerAvailability = /* GraphQL */ `
  mutation DeleteCleenerAvailability($input: DeleteCleenerAvailabilityInput!) {
    deleteCleenerAvailability(input: $input) {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const createCleenerItinerary = /* GraphQL */ `
  mutation CreateCleenerItinerary($input: CreateCleenerItineraryInput!) {
    createCleenerItinerary(input: $input) {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
export const updateCleenerItinerary = /* GraphQL */ `
  mutation UpdateCleenerItinerary($input: UpdateCleenerItineraryInput!) {
    updateCleenerItinerary(input: $input) {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
export const deleteCleenerItinerary = /* GraphQL */ `
  mutation DeleteCleenerItinerary($input: DeleteCleenerItineraryInput!) {
    deleteCleenerItinerary(input: $input) {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
