import * as yup from 'yup';
import moment from 'moment';


// const DriveNow = {
//     "fields": [
//         { label: 'Order ID', type: 'input', name: 'orderId', value: '' },
//         {
//             label: 'Order Type', type: 'select', name: 'serviceOrderType', value: '', optionsName: 'serviceOrderTypeOptions', optionsValues: {
//                 "cleaning (internal & external)": { label: "Cleaning - Internal & External", access: ["cleeners"] },
//                 "cleaning (internal)": { label: "Cleaning - Internal only", access: ["cleeners"] },
//                 "cleaning (external)": { label: "Cleaning - External only", access: ["cleeners"] },
//                 "quality check": { label: "Quality check", access: ["citymanagers"] },
//                 "shuttle": { label: "Shuttle", access: ["cleeners","citymanagers"] }
//             },
//             onChangeCallbackName: "setCleenerList"
//         },
//         { label: 'Completion From', type: 'datetime', name: 'completionFrom', value: '' },
//         { label: 'Completion Until', type: 'datetime', name: 'completionUntil', value: '' },
//         { label: 'Car Type', type: 'input', name: 'carType', value: '' },
//         { label: 'Plate Number', type: 'input', name: 'plateNumber', value: '' },
//         { label: 'Position', type: 'locationinput', name: 'position' },
//         { label: 'Cleener', type: 'select', name: 'cleener', value: '', optionsName: 'cleenerOptions', optionsValues: {} },
//         { label: 'Comment', type: 'input', name: 'comment', value: '' },
//     ],
//     "schema": yup.object({
//         orderId: yup.string().required('Required').matches(/^\d{7}$/,"Invalid format. Example: 1234567"),
//         serviceOrderType: yup.string().required("Required"),
//         carType: yup.string().required("Required"),
//         completionFrom: yup.date().required("Required"),
//         completionUntil: yup.date().required("Required"),
//         plateNumber: yup.string().required('Required').matches(/^[A-Z]{1,3}-[A-Z]{1,2} [0-9]{1,4}E?$/,"Invalid format. Example: M-DX 1234"),
//         position: yup.object().shape({
//             value: yup.string().required('Required')
//         }),
//         cleener: yup.string().required("Required"),
//         comment: yup.string(),
//     }),
//     "formInitValues": {
//         orderId: "",
//         serviceOrderType: "",
//         completionFrom: moment().toDate(),
//         completionUntil: moment().add(2, 'hours').toDate(),
//         carType: "",
//         plateNumber: "",
//         cleener: "",
//         comment: "",
//         position: ""
//     }
// }

// const Sixt = {
//     "fields": [
//         { label: 'Order ID', type: 'input', name: 'orderId', value: '' },
//         {
//             label: 'Order Type', type: 'select', name: 'serviceOrderType', value: '', optionsName: 'serviceOrderTypeOptions', optionsValues: {
//                 "cleaning (internal & external)": { label: "Cleaning - Internal & External", access: ["cleeners"] },
//                 "cleaning (internal)": { label: "Cleaning - Internal only", access: ["cleeners"] },
//                 "cleaning (external)": { label: "Cleaning - External only", access: ["cleeners"] },
//                 "quality check": { label: "Quality check", access: ["citymanagers"] },
//             },
//             onChangeCallbackName: "setCleenerList"
//         },
//         { label: 'Completion From', type: 'datetime', name: 'completionFrom', value: '' },
//         { label: 'Completion Until', type: 'datetime', name: 'completionUntil', value: '' },
//         { label: 'Car Type', type: 'input', name: 'carType', value: '' },
//         { label: 'Plate Number', type: 'input', name: 'plateNumber', value: '' },
//         { label: 'Position', type: 'locationinput', name: 'position' },
//         { label: 'Cleener', type: 'select', name: 'cleener', value: '', optionsName: 'cleenerOptions', optionsValues: {} },
//         { label: 'Comment', type: 'input', name: 'comment', value: '' },
//     ],
//     "schema": yup.object({
//         orderId: yup.string().required('Required').matches(/^SO-\d{8}-\d{4,6}$/,"Invalid format. Example: SO-20191001-1234"),
//         serviceOrderType: yup.string().required("Required"),
//         carType: yup.string().required("Required"),
//         completionFrom: yup.date().required("Required"),
//         completionUntil: yup.date().required("Required"),
//         plateNumber: yup.string().required('Required').matches(/^[A-Z]{1,3}-[A-Z]{1,2} [0-9]{1,4}E?$/,"Invalid format. Example: M-DX 1234"),
//         position: yup.object().shape({
//             value: yup.string().required('Required')
//         }),
//         cleener: yup.string().required("Required"),
//         comment: yup.string(),
//     }),
//     "formInitValues": {
//         orderId: "",
//         serviceOrderType: "",
//         completionFrom: moment().toDate(),
//         completionUntil: moment().add(2, 'hours').toDate(),
//         carType: "",
//         plateNumber: "",
//         cleener: "",
//         comment: "",
//         position: ""
//     }
// }

// const Drivy = {
//     "fields": [
//         { label: 'Order ID', type: 'input', name: 'orderId', value: '' },
//         {
//             label: 'Order Type', type: 'select', name: 'serviceOrderType', value: '', optionsName: 'serviceOrderTypeOptions', optionsValues: {
//                 "cleaning (internal & external)": { label: "Cleaning - Internal & External", access: ["cleeners"] },
//                 "cleaning (internal)": { label: "Cleaning - Internal only", access: ["cleeners"] },
//                 "cleaning (external)": { label: "Cleaning - External only", access: ["cleeners"] },
//             },
//             onChangeCallbackName: "setCleenerList"
//         },
//         { label: 'Completion From', type: 'datetime', name: 'completionFrom', value: '' },
//         { label: 'Completion Until', type: 'datetime', name: 'completionUntil', value: '' },
//         { label: 'Car Type', type: 'input', name: 'carType', value: '' },
//         { label: 'Plate Number', type: 'input', name: 'plateNumber', value: '' },
//         { label: 'Position', type: 'locationinput', name: 'position' },
//         { label: 'Car Open Link', type: 'input', name: 'carOpenLink', value: '' },
//         {
//             label: 'Cleener', type: 'select', name: 'cleener', value: '', optionsName: 'cleenerOptions', optionsValues: {}
//         },
//         { label: 'Comment', type: 'input', name: 'comment', value: '' },
//     ],
//     "schema": yup.object({
//         orderId: yup.string().required('Required').matches(/^\d{6,7}$/,'Invalid format. Example: 123456'),
//         serviceOrderType: yup.string().required('Required'),
//         carType: yup.string().required('Required'),
//         completionFrom: yup.date().required('Required'),
//         completionUntil: yup.date().required('Required'),
//         plateNumber: yup.string().required('Required').matches(/^[A-Z]{3,4}\d{3,4}$/,'Invalid format. Example: DOFX5579'),
//         position: yup.object().shape({
//             value: yup.string().required('Required')
//         }),
//         carOpenLink: yup.string().url('Invalid valid web address. Example: https://example.com'),
//         cleener: yup.string().required('Required'),
//         comment: yup.string(),
//     }),
//     "formInitValues": {
//         orderId: "",
//         serviceOrderType: "",
//         completionFrom: moment().toDate(),
//         completionUntil: moment().add(2, 'hours').toDate(),
//         carType: "",
//         plateNumber: "",
//         carOpenLink: "https://",
//         cleener: "",
//         comment: "",
//     }
// }


const GreenWheels = {
    "fields": [
        {label:"Map view",type:"POImap",name:"POImap"},
        { label: 'Report Number', type: 'input', name: 'orderId', value: '' },
        {
            label: 'Order Type', type: 'select', name: 'serviceOrderType', value: '', optionsName: 'serviceOrderTypeOptions', optionsValues: {
                "cleaning (internal & external)": { label: "Cleaning - Internal & External", access: ["cleeners"] },
                "cleaning (internal)": { label: "Cleaning - Internal only", access: ["cleeners"] },
                "cleaning (external)": { label: "Cleaning - External only", access: ["cleeners"] },
                "transfer": { label: "Car transfer", access: ["cleeners"] },
                "battery drive": { label: "Battery maintenance drive", access: ["cleeners"] },
                "repair": { label: "Repair", access: ["cleeners"] },
                "sticker setup": { label: "Sticker setup", access: ["cleeners"] },
            },
            onChangeCallbackName: "setCleenerList"
        },
        { label: 'Completion From', type: 'datetime', name: 'completionFrom', value: '' },
        { label: 'Completion Until', type: 'datetime', name: 'completionUntil', value: '' },
        { label: 'Car Type', type: 'input', name: 'carType', value: '' },
        { label: 'Plate Number', type: 'input', name: 'plateNumber', value: '' },
        { label: 'Address', type: 'locationinput', name: 'position' },
        { label: 'GPS coordinates', type: 'input', name: 'positionGPS', value: '' },
        { label: 'Car Open Link', type: 'input', name: 'carOpenLink', value: '' },
        {
            label: 'Cleener', type: 'select', name: 'cleener', value: '', optionsName: 'cleenerOptions', optionsValues: {}
        },
        { label: 'Comment', type: 'input', name: 'comment', value: '' },
    ],
    "schema": yup.object({
        orderId: yup.string().required('Required').matches(/^\d{6,7}$/,'Invalid format. Example: 123456'),
        serviceOrderType: yup.string().required('Required'),
        carType: yup.string().required('Required'),
        completionFrom: yup.date().required('Required'),
        completionUntil: yup.date().required('Required'),
        plateNumber:yup.string().required('Required').matches(/^[A-Z]{1,3}-?[A-Z]{1,2} ?[0-9]{1,4}E?$/,"Invalid format. Example: M-DX 1234"),
        positionGPS:yup.string().matches(/^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/,"Invalid format. Example: 52.52437, 13.41053"),
        position: yup.object().shape({
            address: yup.string().required('Required')
        }),
        carOpenLink: yup.string().url('Invalid valid web address. Example: https://example.com'),
        cleener: yup.string().required('Required'),
        comment: yup.string(),
    }),
    "formInitValues": {
        orderId: "",
        serviceOrderType: "",
        completionFrom: moment().toDate(),
        completionUntil: moment().add(2, 'hours').toDate(),
        carType: "",
        plateNumber: "",
        carOpenLink: "https://",
        cleener: "",
        comment: "",
        positionGPS:"",
        position:{address:"",coordinates:null}
    }
}

const CondeNast = {
    "fields": [
        { label: 'Order ID', type: 'input', name: 'orderId', value: '' },
        {
            label: 'Order Type', type: 'select', name: 'serviceOrderType', value: '', optionsName: 'serviceOrderTypeOptions', optionsValues: {
                "cleaning (internal & external)": { label: "Cleaning - Internal & External", access: ["cleeners"] },
                "cleaning (internal)": { label: "Cleaning - Internal only", access: ["cleeners"] },
                "cleaning (external)": { label: "Cleaning - External only", access: ["cleeners"] },
            },
            onChangeCallbackName: "setCleenerList"
        },
        { label: 'Completion From', type: 'datetime', name: 'completionFrom', value: '' },
        { label: 'Completion Until', type: 'datetime', name: 'completionUntil', value: '' },
        { label: 'Car Type', type: 'input', name: 'carType', value: '' },
        { label: 'Plate Number', type: 'input', name: 'plateNumber', value: '' },
        { label: 'Position', type: 'locationinput', name: 'position' },
        {
            label: 'Cleener', type: 'select', name: 'cleener', value: '', optionsName: 'cleenerOptions', optionsValues: {}
        },
        { label: 'Comment', type: 'input', name: 'comment', value: '' },
    ],
    "schema": yup.object({
        orderId: yup.string().required('Required'),
        serviceOrderType: yup.string().required('Required'),
        carType: yup.string().required('Required'),
        completionFrom: yup.date().required('Required'),
        completionUntil: yup.date().required('Required'),
        plateNumber: yup.string().required('Required'),
        position: yup.object().shape({
            value: yup.string().required('Required')
        }),
        cleener: yup.string().required('Required'),
        comment: yup.string(),
    }),
    "formInitValues": {
        orderId: "",
        serviceOrderType: "",
        completionFrom: moment().toDate(),
        completionUntil: moment().add(2, 'hours').toDate(),
        carType: "",
        plateNumber: "",
        cleener: "",
        comment: "",
        position:{value:"",address:"",coordinates:null}
    }
}

const ClientConfig = {
    // "Drivy": {
    //     "Berlin": Drivy,
    // },
    "GreenWheels": {
        "Berlin": GreenWheels,
        "Hamburg": GreenWheels,
    },
    "CondeNast": {
        "Munich": CondeNast,
    },
    // "Sixt": {
    //     "Berlin": Sixt,
    //     "Munich": Sixt,
    //     "Hamburg": Sixt
    // },
    "DriveNow": {
        "Budapest": null,
    }
}

export { ClientConfig };