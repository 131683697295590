/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPreorder = /* GraphQL */ `
  query GetPreorder($id: ID!) {
    getPreorder(id: $id) {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const listPreorders = /* GraphQL */ `
  query ListPreorders(
    $filter: ModelPreorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreorders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        orderId
        serviceDateFrom
        serviceDateTill
        plateNumber
        carType
        orderType
        client
        location
        createdBy
        requestPayload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
        deviceName
        city
        installationId
        cleenerId
        isBackground
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preorderByClientByLocationByTimestamp = /* GraphQL */ `
  query PreorderByClientByLocationByTimestamp(
    $client: String
    $locationTimestamp: ModelPreorderByClientByLocationByTimestampCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preorderByClientByLocationByTimestamp(
      client: $client
      locationTimestamp: $locationTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        orderId
        serviceDateFrom
        serviceDateTill
        plateNumber
        carType
        orderType
        client
        location
        createdBy
        requestPayload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preorderByClientByLocation = /* GraphQL */ `
  query PreorderByClientByLocation(
    $client: String
    $location: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preorderByClientByLocation(
      client: $client
      location: $location
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        orderId
        serviceDateFrom
        serviceDateTill
        plateNumber
        carType
        orderType
        client
        location
        createdBy
        requestPayload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preorderByClientByTimestamp = /* GraphQL */ `
  query PreorderByClientByTimestamp(
    $client: String
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preorderByClientByTimestamp(
      client: $client
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        orderId
        serviceDateFrom
        serviceDateTill
        plateNumber
        carType
        orderType
        client
        location
        createdBy
        requestPayload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preorderByLocationByTimestamp = /* GraphQL */ `
  query PreorderByLocationByTimestamp(
    $location: String
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preorderByLocationByTimestamp(
      location: $location
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        orderId
        serviceDateFrom
        serviceDateTill
        plateNumber
        carType
        orderType
        client
        location
        createdBy
        requestPayload
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByCleenerByInstallByTimestamp = /* GraphQL */ `
  query LocationByCleenerByInstallByTimestamp(
    $cleenerId: Int
    $installationIdTimestamp: ModelLocationByCleenerByInstallByTimestampCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCleenerByInstallByTimestamp(
      cleenerId: $cleenerId
      installationIdTimestamp: $installationIdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
        deviceName
        city
        installationId
        cleenerId
        isBackground
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByCleenerByInstall = /* GraphQL */ `
  query LocationByCleenerByInstall(
    $cleenerId: Int
    $installationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCleenerByInstall(
      cleenerId: $cleenerId
      installationId: $installationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
        deviceName
        city
        installationId
        cleenerId
        isBackground
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByCleenerByTimestamp = /* GraphQL */ `
  query LocationByCleenerByTimestamp(
    $cleenerId: Int
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCleenerByTimestamp(
      cleenerId: $cleenerId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
        deviceName
        city
        installationId
        cleenerId
        isBackground
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByCityByTimestamp = /* GraphQL */ `
  query LocationByCityByTimestamp(
    $city: String
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCityByTimestamp(
      city: $city
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        latitude
        longitude
        altitude
        accuracy
        altitudeAccuracy
        heading
        speed
        deviceName
        city
        installationId
        cleenerId
        isBackground
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCleenerAvailability = /* GraphQL */ `
  query GetCleenerAvailability($id: ID!) {
    getCleenerAvailability(id: $id) {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const listCleenerAvailabilitys = /* GraphQL */ `
  query ListCleenerAvailabilitys(
    $filter: ModelCleenerAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCleenerAvailabilitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceName
        city
        owner
        installationId
        cleenerId
        availabilityStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cleenerAvailabilityByCleenerByTimestamp = /* GraphQL */ `
  query CleenerAvailabilityByCleenerByTimestamp(
    $cleenerId: Int
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleenerAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleenerAvailabilityByCleenerByTimestamp(
      cleenerId: $cleenerId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceName
        city
        owner
        installationId
        cleenerId
        availabilityStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cleenerAvailabilityByCleenerByInstallByTimestamp = /* GraphQL */ `
  query CleenerAvailabilityByCleenerByInstallByTimestamp(
    $cleenerId: Int
    $installationIdTimestamp: ModelCleenerAvailabilityByCleenerByInstallByTimestampCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleenerAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleenerAvailabilityByCleenerByInstallByTimestamp(
      cleenerId: $cleenerId
      installationIdTimestamp: $installationIdTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceName
        city
        owner
        installationId
        cleenerId
        availabilityStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cleenerAvailabilityByCityByTimestamp = /* GraphQL */ `
  query CleenerAvailabilityByCityByTimestamp(
    $city: String
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleenerAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleenerAvailabilityByCityByTimestamp(
      city: $city
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceName
        city
        owner
        installationId
        cleenerId
        availabilityStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCleenerItinerary = /* GraphQL */ `
  query GetCleenerItinerary($id: ID!) {
    getCleenerItinerary(id: $id) {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
export const listCleenerItinerarys = /* GraphQL */ `
  query ListCleenerItinerarys(
    $filter: ModelCleenerItineraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCleenerItinerarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        city
        routes
        cards
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cleenerItineraryByCityByTimestamp = /* GraphQL */ `
  query CleenerItineraryByCityByTimestamp(
    $city: String
    $timestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCleenerItineraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cleenerItineraryByCityByTimestamp(
      city: $city
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        city
        routes
        cards
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
