import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as config from './client-config.js';
import Col from 'react-bootstrap/Col';
import MySwal from './MySwal';

import moment from 'moment';
import { FaFileDownload } from 'react-icons/fa';
const { SearchBar } = Search;
const { ClientConfig } = config;

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Button variant="secondary" onClick={handleClick}><FaFileDownload/>&nbsp;Download</Button>
  );
};


class CompletedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedOrders: [],
      isDataLoading: false,
      location: "",
      interval: ""
    }
    this.renderNoDataIndication = this.renderNoDataIndication.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleIntervalChange = this.handleIntervalChange.bind(this);
  }

  componentDidMount() {
    var intervalOptions = [];
    intervalOptions.push(<option key="blank" value="">Select date interval</option>)
    intervalOptions.push(<option key="0" value="INTERVAL_0">Today</option>)
    intervalOptions.push(<option key="6" value="INTERVAL_6">Last 7 days</option>)
    intervalOptions.push(<option key="30" value="INTERVAL_30">Last 30 days</option>)
    intervalOptions.push(<option key="92" value="INTERVAL_92">Last 90 days</option>)
    intervalOptions.push(<option key="THIS_MONTH" value="THIS_MONTH">This Month</option>)
    intervalOptions.push(<option key="LAST_MONTH" value="LAST_MONTH">Last Month</option>)

    var locationOptions = [];
    var locations = []
    Object.keys(ClientConfig).forEach(client => Object.keys(ClientConfig[client]).forEach(location => locations.push(location)))
    locations = Array.from(new Set(locations));

    locationOptions.push(<option key="blank" value="">Select location</option>)
    locations.forEach(location => locationOptions.push(<option key={location} value={location}>{location}</option>))
    
    this.setState({ 
      locationOptions: locationOptions, 
      location: locationOptions[0].props.value,
      intervalOptions : intervalOptions,
      interval: intervalOptions[0].props.value,
     });

  
  }

  renderNoDataIndication(props) {
    return (
      <React.Fragment>
        {this.state.isDataLoading &&
          <Spinner animation="border" />
        }
        {!this.state.isDataLoading &&
          <p>No data</p>
        }
      </React.Fragment>
    )
  }
  getCompletedOrders() {
    if(this.state.location === "" || this.state.interval === "") {
      return
    }

    this.setState({ isDataLoading: true, completedOrders: []}, () =>
    Auth.currentCredentials().then(credentials => {
      const cloudformation = new CloudFormation({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "eu-west-1"
      });

      var params = {
        StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
      };
      cloudformation.describeStacks(params).promise().then(data => {
        var awsStackOutputs = data.Stacks[0].Outputs
        return awsStackOutputs.filter(obj => {
          return obj.OutputKey === "getCompletedOrdersArn";
        })[0].OutputValue
      }, error => {
        console.log(error)
      }).then((functionName) => {
        var payload = {
          "location": this.state.location,
          
        }

        switch(this.state.interval) {
          case "INTERVAL_0":
            payload["interval_days"]="0"
            break
          case "INTERVAL_6":
            payload["interval_days"]="6"
            break
          case "INTERVAL_30":
            payload["interval_days"]="30"
            break
          case "INTERVAL_92":
            payload["interval_days"]="92"
            break
          case "THIS_MONTH":
            let now = moment()
            payload["year"]=now.year()
            payload["month"]=now.month()+1
            break
          case "LAST_MONTH":
            let lastMonth = moment().subtract(1, 'months')
            payload["year"]=lastMonth.year()
            payload["month"]=lastMonth.month()+1
            break

          default:

        }
        var pullParams = {
          FunctionName: functionName,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify(payload)
        };
        return lambda.invoke(pullParams).promise();
      }).then(
        result => {
          let payload = JSON.parse(result.Payload);
          if (payload.statusCode === 200) {
            try {
              let body = JSON.parse(payload.body);
              body.forEach((row) => {
                row["Client Order ID"] = <a href={row["Trello Card URL"]} target="_blank" rel="noopener noreferrer">{row["Client Order ID"]}</a>
                row["MakeModel"] = row["Make"] + " "+row["Model"]
              })
              this.setState({ completedOrders: body })
            }
            catch(err){
              MySwal.displaySystemError(err)
            }
          }
          else {
            MySwal.displaySystemError(payload)
          }
        },
        err => {
          MySwal.displaySystemError(err)

        }).finally(() => this.setState({ isDataLoading: false }))

    }))
  }

  handleLocationChange(event) {
        let location = event.target.value
        this.setState({ location: location }, this.getCompletedOrders);
  }

  handleIntervalChange(event) {
    let interval = event.target.value
    this.setState({ interval: interval }, this.getCompletedOrders);
}
  render() {

    const columns = [

      {
        dataField: 'Client Order ID',
        text: 'Order ID',
        //sort: true
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Client',
        text: 'Client',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Car plate',
        text: 'Car plate',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'MakeModel',
        text: 'Model',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Description',
        text: 'Category',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Order type',
        text: 'Order type',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Order date',
        text: 'Order date',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },

      {
        dataField: 'Completion date',
        text: 'Completion date',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Cleaner Name',
        text: 'Cleaner Name',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Total Price',
        text: `Total Price (${this.state.location==="Budapest"?"Ft":"€"})`,
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Total Score',
        text: 'Cleener Points',
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      },
      {
        dataField: 'Total Bonus',
        text: `Cleener Bonus (${this.state.location==="Budapest"?"Ft":"€"})`,
        sort: true,
        style: {overflow: "hidden","text-overflow":"ellipsis"},
        headerStyle: {overflow: "hidden","text-overflow":"ellipsis"}
      }];

    const defaultSorted = [{
      dataField: 'Completion date',
      order: 'desc'
    }];

    const sumTotalPrice = this.state.completedOrders.reduce((acc,curr)=>acc+curr["Total Price"],0)
    const sumCleenerBonus = this.state.completedOrders.reduce((acc,curr)=>acc+curr["Total Bonus"],0)
    const sumCleenerPoints = this.state.completedOrders.reduce((acc,curr)=>acc+curr["Total Score"],0)
    return (
      <Container fluid>
        <ToolkitProvider
          keyField='Card ID' bootstrap4 data={this.state.completedOrders} columns={columns} 
          search
          exportCSV
        >
          {
            props => (
              <div className='mt-5'>
                <Form >
                  <Row>

                    <Col>
                      <Form.Control as="select"  onChange={this.handleLocationChange} value={this.state.location}>
                        {this.state.locationOptions}
                      </Form.Control>
                      </Col>
                    <Col>

                      <Form.Control as="select" onChange={this.handleIntervalChange} value={this.state.interval}>
                        {this.state.intervalOptions}
                      </Form.Control>

                    </Col>
                    <Col>
                      <SearchBar autocomplete={"off"} {...props.searchProps} />
                    </Col>
                    <Col>
                    <Row style={{fontSize: 9, fontWeight:"bold"}}>
                    {(sumTotalPrice && sumTotalPrice!==0) ? `Price: ${Math.round(sumTotalPrice)} ${this.state.location==="Budapest"?"Ft":"€"}` : null}
                    </Row>
                    <Row style={{fontSize: 9, fontWeight:"bold"}}>
                    {(sumTotalPrice && sumTotalPrice!==0) ? `Points: ${Math.round(10*sumCleenerPoints/60)/10} hrs` : null}
                    </Row>
                    <Row style={{fontSize: 9, fontWeight:"bold"}}>
                    {(sumTotalPrice && sumTotalPrice!==0) ? `Bonus: ${Math.round(sumCleenerBonus)} ${this.state.location==="Budapest"?"Ft":"€"}` : null}
                    </Row>
                    </Col>
                    <Col>
                      <MyExportCSV {...props.csvProps}>Export</MyExportCSV>
                    </Col>
                  </Row>
                </Form>
                <hr />
                {this.state.location && this.state.interval &&
                <BootstrapTable pagination={paginationFactory({sizePerPage:25})} noDataIndication={this.renderNoDataIndication}
                  {...props.baseProps}
                  defaultSorted={defaultSorted}
                  striped
                  hover
                  condensed
                />}
              </div>
            )
          }
        </ToolkitProvider>
      </Container>)
  }
}

export default CompletedOrders;