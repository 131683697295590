import { Field } from 'formik';
import * as React from 'react';
import { useRef } from 'react';


const empty = Symbol('empty');
const FieldSpy = ({ name, onChange }) => {
    const value = useRef(empty);

    return (
        <Field name={name}>
            {({ field }) => {
                if (value.current !== empty && value.current !== field.value) {
                    onChange(field.value);
                }
                value.current = field.value;

                return null;
            }}
        </Field>
    );
};

export default FieldSpy