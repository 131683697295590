import { withOAuth } from 'aws-amplify-react';
import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
class OAuthButton extends Component {
  render() {
    return (
      <Nav.Link onClick={this.props.OAuthSignIn}>
        Sign in
      </Nav.Link>
    )
  }
}

export default withOAuth(OAuthButton);