/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePreorder = /* GraphQL */ `
  subscription OnCreatePreorder {
    onCreatePreorder {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePreorder = /* GraphQL */ `
  subscription OnUpdatePreorder {
    onUpdatePreorder {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePreorder = /* GraphQL */ `
  subscription OnDeletePreorder {
    onDeletePreorder {
      id
      timestamp
      orderId
      serviceDateFrom
      serviceDateTill
      plateNumber
      carType
      orderType
      client
      location
      createdBy
      requestPayload
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      timestamp
      latitude
      longitude
      altitude
      accuracy
      altitudeAccuracy
      heading
      speed
      deviceName
      city
      installationId
      cleenerId
      isBackground
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCleenerAvailability = /* GraphQL */ `
  subscription OnCreateCleenerAvailability {
    onCreateCleenerAvailability {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCleenerAvailability = /* GraphQL */ `
  subscription OnUpdateCleenerAvailability {
    onUpdateCleenerAvailability {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCleenerAvailability = /* GraphQL */ `
  subscription OnDeleteCleenerAvailability {
    onDeleteCleenerAvailability {
      id
      timestamp
      deviceName
      city
      owner
      installationId
      cleenerId
      availabilityStatus
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCleenerItinerary = /* GraphQL */ `
  subscription OnCreateCleenerItinerary {
    onCreateCleenerItinerary {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCleenerItinerary = /* GraphQL */ `
  subscription OnUpdateCleenerItinerary {
    onUpdateCleenerItinerary {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCleenerItinerary = /* GraphQL */ `
  subscription OnDeleteCleenerItinerary {
    onDeleteCleenerItinerary {
      id
      timestamp
      city
      routes
      cards
      createdAt
      updatedAt
    }
  }
`;
