import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Lambda from 'aws-sdk/clients/lambda';
import CloudFormation from 'aws-sdk/clients/cloudformation';
import { Auth } from 'aws-amplify';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-date-picker';
import MySwal from './MySwal';
import AdjustPointsModal from './AdjustPointsModal';
import ViewPointAdjusmentsModal from './ViewPointAdjusmentsModal';
import Badge from 'react-bootstrap/Badge'
import moment from 'moment';
import { FaPlus, FaMinus, FaFileDownload, FaEye } from 'react-icons/fa';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
const { SearchBar } = Search;

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Button variant="secondary" onClick={handleClick}><FaFileDownload/>&nbsp;Download</Button>
  );
};


class CleenersQuota extends Component {
  constructor(props) {
    super(props);
    
    var monthOptions = [];
    monthOptions.push(<option key="blank" value="">Select month</option>)
    for (var i = 1; i < 13; i++) {
      monthOptions.push(<option key={i.toString()} value={i.toString()}>{i.toString()}</option>)
    }

    var yearOptions = [];
    yearOptions.push(<option key="blank" value="">Select year</option>)
    yearOptions.push(<option key="2019" value="2019">2019</option>)
    yearOptions.push(<option key="2018" value="2018">2018</option>)
    
    this.state = {
      cleenersQuota: [],
      isDataLoading: false,
      yearmonth: new Date(),
      selectedCleenerID: null,
      yearOptions: yearOptions,
      location: yearOptions[0].props.value,
      monthOptions: monthOptions,
      interval: monthOptions[0].props.value,
      showViewPointAdjusmentsModal: false
    }
    this.adjustPointsModal = React.createRef();
    this.renderNoDataIndication = this.renderNoDataIndication.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.getCleenersQuota = this.getCleenersQuota.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.getCleenersQuota();
  }

  renderNoDataIndication(props) {
    return (
      <React.Fragment>
        {this.state.isDataLoading &&
          <Spinner animation="border" />
        }
        {!this.state.isDataLoading &&
          <p>No data</p>
        }
      </React.Fragment>
    )
  }
  getCleenersQuota() {
    this.setState({ isDataLoading: true, cleenersQuota: [], selectedCleenerID: null }, () =>
      Auth.currentCredentials().then(credentials => {
        const cloudformation = new CloudFormation({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: "eu-west-1"
        });

        var params = {
          StackName: process.env.REACT_APP_AWS_BACKEND_STACK_NAME
        };
        cloudformation.describeStacks(params).promise().then(data => {
          var awsStackOutputs = data.Stacks[0].Outputs
          return awsStackOutputs.filter(obj => {
            return obj.OutputKey === "getCleenersQuotaArn";
          })[0].OutputValue
        }, error => {
          console.log(error)
        }).then((functionName) => {
          var payload = {
            "year": this.state.yearmonth.getFullYear(),
            "month": this.state.yearmonth.getMonth() + 1,
          }
          var pullParams = {
            FunctionName: functionName,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: JSON.stringify(payload)
          };
          return lambda.invoke(pullParams).promise();
        }).then(
          result => {
            let payload = JSON.parse(result.Payload);
            if (payload.statusCode === 200) {
              try {
                let body = JSON.parse(payload.body);
                body.forEach(item=>item["Total Score with adjustment"]=item["Total Score"]+item["Score adjustment"])
                body.forEach(item=>{
                  let goalPerc = Math.round(item["Total Score with adjustment"]/item["MonthlyQuota"]*100)
                  item["Goal"]=isFinite(goalPerc)?Math.max(0,goalPerc):null

                  let goalRealWorkPerc = Math.round(item["Total Score"]/(Math.min(item["Real Worked Days Count"],item["WorkingDaysMonth"])*item["DailyQuota"])*100)
                  item["GoalReal"]=isFinite(goalRealWorkPerc)?Math.max(0,goalRealWorkPerc):null
                  item["WorkingDaysRatio"]=`${item["Real Worked Days Count"]?item["Real Worked Days Count"]:"-"}/${item["WorkingDaysMonth"]?item["WorkingDaysMonth"]:"-"}`
                  item["WorkingDaysWithLimit"]=`${item["Real Worked Days Count With Limit"]?item["Real Worked Days Count With Limit"]:"-"}`
                })
                this.setState({ cleenersQuota: body })
              }
              catch(err){
                MySwal.displaySystemError(payload)
              }
            }
            else {
              MySwal.displaySystemError(payload)
            }
          },
          err => {
            MySwal.displaySystemError(err)

          }).finally(() => this.setState({ isDataLoading: false }))

      }))
  }


 

  handleYearMonthChange(date) {
    this.setState({ yearmonth: date }, this.getCleenersQuota);
  }

  onClick(event, action) {
    this.adjustPointsModal.current.handleShow(action);
  }
  render() {
    const columns = [
      {
        dataField: 'Cleaner Name',
        text: 'Name',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let name = row["Cleaner Name"]
          if(moment(this.state.yearmonth).isSame(row["Start Date"],'month')) name += " (new joiner)"
          return (<React.Fragment>{name}</React.Fragment>)
        }
      },
      {
        dataField: 'Location',
        text: 'Location',
        align: 'center',
        sort: true
      },
      {
        dataField: 'Total Bonus',
        text: 'Bonus',
        align: 'center',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if(cell==null) return (<React.Fragment>-</React.Fragment>)
          return (<React.Fragment>€{cell}</React.Fragment>)
        }
      },
      {
        dataField: 'Total Score',
        text: 'Score',
        align: 'center',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if(cell==null) return (<React.Fragment>-</React.Fragment>)
          else return (<React.Fragment>{cell}</React.Fragment>)
        }
        
      },
      {
        dataField: 'Score adjustment',
        text: 'Score Extra',
        align: 'center',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          if(cell==null) return (<React.Fragment>-</React.Fragment>)
          else return (<React.Fragment>{cell}</React.Fragment>)
        }
  
      },
      {
        dataField: 'Total Score with adjustment',
        text: 'Score Total',
        align: 'center',
        sort: true
      },
      {
        dataField: 'MonthlyQuota',
        text: 'Score Goal',
        align: 'center',
        sort: true
      },
      {
        dataField: 'WorkingDaysWithLimit',
        text: 'Workdays (>350pts)',
        align: 'center',
        sort: true
      },
      {
        dataField: 'WorkingDaysRatio',
        text: 'Workdays real/target',
        align: 'center',
        sort: true
      },
      {
        dataField: 'Goal',
        text: 'Performance adjusted',
        align: 'center',
        sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if(cell==null) return
        if (cell<80) return (<Badge variant="danger">{cell}%</Badge>)
        else return (<Badge variant="success">{cell}%</Badge>)
      }
      },
      {
        dataField: 'GoalReal',
        text: 'Performance work-only',
        align: 'center',
        sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if(cell==null) return
        if (cell<80) return (<Badge variant="danger">{cell}%</Badge>)
        else return (<Badge variant="success">{cell}%</Badge>)
      }
      }
    ];

    const defaultSorted = [{
      dataField: 'Total Score',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.setState({ selectedCleenerID: row.CleenerID })
      },
    };

    return (
      <Container>
        <AdjustPointsModal ref={this.adjustPointsModal} getcleenersquota={this.getCleenersQuota} cleenerid={this.state.selectedCleenerID} user={this.props.user} />
        { this.state.showViewPointAdjusmentsModal &&
        <ViewPointAdjusmentsModal cleenerid={this.state.selectedCleenerID} yearmonth={this.state.yearmonth} onClose={()=>this.setState({showViewPointAdjusmentsModal:false})} user={this.props.user} />
        }
        <ToolkitProvider
          keyField='Cleaner Name' bootstrap4 data={this.state.cleenersQuota} columns={columns}
          search
          exportCSV
        >
          {
            props => (
              <React.Fragment>
                <Form >
                  <Row>

                    <Col md={2}>
                      <Form.Control as={DatePicker}
                        name="Month"
                        format="y-MM"
                        disableClock={true}
                        maxDetail="year"
                        value={this.state.yearmonth}
                        maxDate={new Date()}
                        minDate={new Date(2018, 4, 1)}
                        calendarIcon={null}
                        clearIcon={null}
                        onChange={this.handleYearMonthChange}
                      />

                    </Col>
                    <Col md={5}>
                      <SearchBar autocomplete={"off"} {...props.searchProps} />
                    </Col>
                    <Col md={5}>
                      <MyExportCSV {...props.csvProps}>Export</MyExportCSV>&nbsp;
                      <ButtonGroup aria-label="Mode">
                      <Button disabled={!this.state.selectedCleenerID} onClick={(event) => this.onClick(event, "add")} variant="secondary"><FaPlus/>&nbsp;Add</Button>
                      <Button disabled={!this.state.selectedCleenerID} onClick={(event) => this.onClick(event, "remove")} variant="secondary"><FaMinus/>&nbsp;Reduce</Button>
                      <Button disabled={!this.state.selectedCleenerID} onClick={() => this.setState({showViewPointAdjusmentsModal:true})} variant="secondary"><FaEye/>&nbsp;View</Button>
                      </ButtonGroup>
                    </Col>

                  </Row>
                </Form>
                <hr />
                {this.state.yearmonth &&
                  <BootstrapTable noDataIndication={this.renderNoDataIndication}
                    {...props.baseProps}
                    defaultSorted={defaultSorted}
                    striped
                    hover
                    condensed
                    selectRow={selectRow}
                  />}
              </React.Fragment>
            )
          }
        </ToolkitProvider>


      </Container>)
  }
}

export default CleenersQuota;